import React from 'react';
import { Property, WorkItem } from '../../types/types';
import { WorkTypes } from '../../types/globals'

interface AddWorkItemProps {
  properties: Property[];
  newWorkItem: WorkItem | undefined;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  handleClose: () => void;
}

const PMWorkItemForm: React.FC<AddWorkItemProps> = ({
  properties,
  newWorkItem,
  handleInputChange,
  handleFileChange,
  handleSubmit,
  handleClose
}) => {
  return (
    <div className="bg-white w-96">
      <h2 className="text-2xl mb-4">Create Internal Work Item</h2>
      <div className="bg-blue-100 text-sm text-blue-800 p-3 rounded mb-4 text-s">
        You can now create a work item by emailing us the details. We handle the rest as usual! Contact <a href="mailto:alexander@joincoflow.com" className="underline">alexander@joincoflow.com</a> to register for this feature.
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Select property:</label>
        <select
          name="propertyId"
          value={newWorkItem?.propertyId}
          onChange={handleInputChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="">Select property</option>
          {properties.map((property: Property) => (
            <option key={property.id} value={property.id}>
              {property.address}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Work type:</label>
        <select
          id="workItemType"
          name="workItemType"
          value={newWorkItem?.workItemType || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="">What kind of issue is this?</option>
          {Object.keys(WorkTypes).map((key) => (
            <option key={key} value={key}>
              {WorkTypes[key as keyof typeof WorkTypes]}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Description:</label>
        <p className="text-xs text-gray-600 mb-1">
         Providing informative description of the issue significantly decreases the time it takes for Coflow AI to get quotes from relevant Contractors!
        </p>
        <textarea
          name="description"
          value={newWorkItem?.description}
          onChange={handleInputChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Add photos:</label>
        <input
          type="file"
          name="photos"
          multiple
          onChange={handleFileChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      <div className="flex justify-end">
        <button
          className="bg-gray-500 text-white px-4 py-2 mr-2 rounded"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="bg-blue-700 text-white px-4 py-2 rounded"
          onClick={handleSubmit}
        >
          Request Quotes
        </button>
      </div>
    </div>
  );
};

export default PMWorkItemForm;

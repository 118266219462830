import {getEnv} from "../utlis";

export enum WorkTypes {
    ElectricalIssue = "Electrical Issue",
    PlumbingProblem = "Plumbing Problem",
    RoofLeak = "Roof Leak",
    PestInfestation = "Pest Infestation",
    HVACMalfunction = "HVAC Malfunction",
    WaterDamage = "Water Damage",
    StructuralCrack = "Structural Crack",
    ApplianceFailure = "Appliance Failure",
    MoldGrowth = "Mold Growth",
    CloggedDrain = "Clogged Drain",
    FoundationIssue = "Foundation Issue",
    GasLeak = "Gas Leak",
    BrokenWindow = "Broken Window",
    DoorMisalignment = "Door Misalignment",
    SewerBackup = "Sewer Backup",
    GutterClog = "Gutter Clog",
    PaintPeeling = "Paint Peeling",
    FlooringDamage = "Flooring Damage",
    GarageDoorProblem = "Garage Door Problem",
    InsulationProblem = "Insulation Problem",
    Cleaning = "Cleaning",
    HeadquarterSetup = "Headquarter Setup",
    Other = "Other",
}

export enum Currency {
    USD = "USD",
    EUR = "EUR",
    GBP = "GBP"
}

export enum WorkItemStatus {
    INITIAL_REPORT = "INITIAL_REPORT",
    QUOTES_AVAILABLE = "QUOTES_AVAILABLE",
    QUOTE_AGREED = "QUOTE_AGREED",
    SCHEDULING_WORK = "SCHEDULING_WORK",
    REVIEWED = "REVIEWED",
    REQUESTING_QUOTES = "REQUESTING_QUOTES",
    QUOTE_SENT_TO_LANDLORD = "QUOTE_SENT_TO_LANDLORD",
    WORK_SENT_TO_PROPERTY_MANAGER = "WORK_SENT_TO_PROPERTY_MANAGER",
    QUOTE_SENT_PROPERTY_MANAGER = "QUOTE_SENT_PROPERTY_MANAGER",
    REMOVED = "REMOVED"
}


export const statusMappingPropertyManager: Partial<Record<WorkItemStatus, string>>  = {
    [WorkItemStatus.INITIAL_REPORT] : "INITIAL REPORT",
    [WorkItemStatus.REQUESTING_QUOTES] : "REQUESTING QUOTES",
    [WorkItemStatus.QUOTES_AVAILABLE]: "QUOTES AVAILABLE",
    [WorkItemStatus.QUOTE_SENT_TO_LANDLORD]: "QUOTES AVAILABLE",
    [WorkItemStatus.QUOTE_SENT_PROPERTY_MANAGER]: "QUOTES AVAILABLE",
    [WorkItemStatus.QUOTE_AGREED]: "QUOTE AGREED",
    [WorkItemStatus.WORK_SENT_TO_PROPERTY_MANAGER]: "WORK COMPLETED",
    [WorkItemStatus.REVIEWED]: "WORK COMPLETED",
    [WorkItemStatus.REMOVED]: "WORK ITEM REMOVED"
};

export const statusMappingLandlord: Partial<Record<WorkItemStatus, string>>  = {
    [WorkItemStatus.QUOTES_AVAILABLE]: "QUOTES BEING REVIEWED BY PROPERTY MANAGER",
    [WorkItemStatus.QUOTE_SENT_TO_LANDLORD]: "QUOTES AVAILABLE",
    [WorkItemStatus.QUOTE_SENT_PROPERTY_MANAGER]: "QUOTES SENT TO PROPERTY MANAGER",
    [WorkItemStatus.QUOTE_AGREED]: "QUOTE AGREED",
    [WorkItemStatus.WORK_SENT_TO_PROPERTY_MANAGER]: "WORK COMPLETED",
    [WorkItemStatus.REVIEWED]: "WORK COMPLETED",
    [WorkItemStatus.REMOVED]: "WORK ITEM REMOVED"
};

export const statusMappingTenant: Partial<Record<WorkItemStatus, string>>  = {
    [WorkItemStatus.INITIAL_REPORT] : "SCHEDULING WORK",
    [WorkItemStatus.REQUESTING_QUOTES] : "SCHEDULING WORK",
    [WorkItemStatus.QUOTES_AVAILABLE]: "SCHEDULING WORK",
    [WorkItemStatus.QUOTE_SENT_TO_LANDLORD]: "SCHEDULING WORK",
    [WorkItemStatus.QUOTE_SENT_PROPERTY_MANAGER]: "SCHEDULING WORK",
    [WorkItemStatus.QUOTE_AGREED]: "SCHEDULING WORK",
    [WorkItemStatus.WORK_SENT_TO_PROPERTY_MANAGER]: "SCHEDULING WORK",
    [WorkItemStatus.REVIEWED]: "WORK COMPLETED",
    [WorkItemStatus.REMOVED]: "WORK ITEM REMOVED"
};


export const statusColors: Record<WorkItemStatus, string> = {
    [WorkItemStatus.INITIAL_REPORT]: 'bg-red-100',
    [WorkItemStatus.REQUESTING_QUOTES]: 'bg-yellow-300',
    [WorkItemStatus.QUOTES_AVAILABLE]: 'bg-yellow-200',
    [WorkItemStatus.QUOTE_SENT_TO_LANDLORD]: 'bg-yellow-200',
    [WorkItemStatus.QUOTE_SENT_PROPERTY_MANAGER]: 'bg-yellow-200',
    [WorkItemStatus.QUOTE_AGREED]: 'bg-yellow-200',
    [WorkItemStatus.SCHEDULING_WORK]: 'bg-yellow-100',
    [WorkItemStatus.WORK_SENT_TO_PROPERTY_MANAGER]: 'bg-green-100',
    [WorkItemStatus.REVIEWED]: 'bg-green-200',
    [WorkItemStatus.REMOVED]: "bg-white"
};

export const subHeaderPropertyManager: Partial<Record<WorkItemStatus, string>> = {
    [WorkItemStatus.INITIAL_REPORT]: "Please review",
    [WorkItemStatus.QUOTES_AVAILABLE]: "Quotes available",
    [WorkItemStatus.QUOTE_SENT_PROPERTY_MANAGER]: "Please review",
    [WorkItemStatus.QUOTE_SENT_TO_LANDLORD]: "Awaiting landlord decision",
    [WorkItemStatus.QUOTE_AGREED]: "Scheduling work",
    [WorkItemStatus.WORK_SENT_TO_PROPERTY_MANAGER]: "Please review",
    [WorkItemStatus.REVIEWED]: "Work reviewed",
    [WorkItemStatus.REMOVED]: "Work cancelled"
};

export const footerMessages: Record<WorkItemStatus, string> = {
    [WorkItemStatus.INITIAL_REPORT]: '',
    [WorkItemStatus.REQUESTING_QUOTES]: 'Coflow AI is requesting quotes from suitable contractors in the area.',
    [WorkItemStatus.QUOTES_AVAILABLE]: 'Quotes are available to be sent to landlord',
    [WorkItemStatus.QUOTE_SENT_TO_LANDLORD]: 'Quotes sent to landlord for selection. Coflow AI will arrange repairs upon approval.',
    [WorkItemStatus.QUOTE_AGREED]: 'Quote approved. Contractor will arrange tenant visits and provide a repair report with photos. We will invoice you afterward.',
    [WorkItemStatus.WORK_SENT_TO_PROPERTY_MANAGER]: 'Please review and approve the work.',
    [WorkItemStatus.REVIEWED]: 'Work reviewed and approved. We will handle the invoice payment.',
    [WorkItemStatus.SCHEDULING_WORK]: 'Owner approved the quote. Coflow AI is scheduling repairs.',
    [WorkItemStatus.QUOTE_SENT_PROPERTY_MANAGER]: '',
    [WorkItemStatus.REMOVED]: "Work has been cancelled"
};

export const statusActionsPropertyManager: Record<WorkItemStatus, { label: string, status: WorkItemStatus }[]> = {
    [WorkItemStatus.INITIAL_REPORT]: [{ label: 'Request Quotes', status: WorkItemStatus.REQUESTING_QUOTES }],
    [WorkItemStatus.REQUESTING_QUOTES]: [],
    [WorkItemStatus.QUOTES_AVAILABLE]: [{ label: 'Send Quotes to Landlord', status: WorkItemStatus.QUOTE_SENT_TO_LANDLORD }],
    [WorkItemStatus.QUOTE_AGREED]: [],
    [WorkItemStatus.REVIEWED]: [],
    [WorkItemStatus.SCHEDULING_WORK]: [],
    [WorkItemStatus.QUOTE_SENT_TO_LANDLORD]: [],
    [WorkItemStatus.WORK_SENT_TO_PROPERTY_MANAGER]: [{ label: 'Approve Work', status: WorkItemStatus.REVIEWED }],
    // PM will move on to the next state via the Quote Approval flow
    [WorkItemStatus.QUOTE_SENT_PROPERTY_MANAGER]: [],
    [WorkItemStatus.REMOVED]: []
};

export enum UserRole {
    PropertyManager = 'PropertyManager',
    Landlord = 'Landlord',
    Tenant = 'Tenant'
  }

export const roleToPathMap: Record<UserRole, string> = {
    [UserRole.Landlord]: '/landlord',
    [UserRole.PropertyManager]: '/propertyManager',
    [UserRole.Tenant]: '/report',
};

export const Config = {
    S3_BUCKET : getEnv("REACT_APP_S3_BUCKET"),
    REGION : getEnv("REACT_APP_REGION"),
    AWS_ACCESS_KEY_ID : getEnv("REACT_APP_AWS_ACCESS_KEY_ID"),
    AWS_SECRET_ACCESS_KEY : getEnv("REACT_APP_AWS_SECRET_ACCESS_KEY"),
    API_BASE_URL : getEnv("REACT_APP_API_BASE_URL"),
    STRIPE_PUBLIC_KEY: getEnv("REACT_APP_STRIPE_PUBLIC_KEY")
} as const;
import React from 'react';
import { Property, Landlord } from '../../types/types';
import Optional from '../commons/Optional';

interface PMPropertyFormProps {
  landlords: Landlord[];
  newProperty: Omit<Property, 'id' | 'propertyManager' | 'workItems' | 'landlord'>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  handleSubmit: () => void;
  handleClose: () => void;
  errorMessage: string;
  isEditMode: boolean;
}

const PMPropertyForm: React.FC<PMPropertyFormProps> = ({
  landlords,
  newProperty,
  handleInputChange,
  handleSubmit,
  handleClose,
  errorMessage,
  isEditMode,
}) => {
  return (
    <div className="bg-white w-96 p-4 rounded">
      <h2 className="text-2xl mb-4">{isEditMode ? 'Edit Property' : 'Add Property'}</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Address</label>
        <input
          type="text"
          name="address"
          value={newProperty.address}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Postcode</label>
        <input
          type="text"
          name="postCode"
          value={newProperty.postCode}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Access Description <Optional /></label>
        <textarea
          name="accessDescription"
          value={newProperty.accessDescription}
          onChange={handleInputChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Landlord</label>
        <select
          name="landlordId"
          value={newProperty.landlordId}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="">Select Landlord</option>
          {landlords.map((landlord) => (
            <option key={landlord.id} value={landlord.id}>
              {landlord.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Threshold for Landlord Work Item Quotes Approval</label>
        <input
          type="number"
          name="defaultThreshold"
          value={newProperty.defaultThreshold}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
        <p className="text-xs text-gray-600 mt-1">
        Any quotes for work orders under this amount will be reviewed by the Property Manager. The Landlord will be sent quotes beyond this amount to review.
        </p>
      </div>
      {errorMessage && (
        <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
          {errorMessage}
        </div>
      )}
      <div className="flex justify-end">
        <button
          className="bg-gray-500 text-white px-4 py-2 mr-2 rounded"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleSubmit}
        >
          {isEditMode ? 'Save Changes' : 'Add Property'}
        </button>
      </div>
    </div>
  );
};

export default PMPropertyForm;

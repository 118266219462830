import {
    LOAD_USER_PROFILE_SUCCESS,
    LOAD_USER_PROFILE_FAIL,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAIL,
} from '../actions/types';
import {PayloadAction} from "@reduxjs/toolkit";
import { Profile } from '../types/types'

const initialState = {
    id: '0',
    username: '',
    email:'',
    name: '',
    phone: '',
    city: '',
    role: '',
    directPayment: false
};

export default function profile(state = initialState, action: PayloadAction<{
    role: string;
    type: string, user: Profile}>) {
    const { type, payload } = action;

    switch(type) {
        case LOAD_USER_PROFILE_SUCCESS:
        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                id: payload.user.id,
                username: payload.user.username,
                email: payload.user.email,
                name: payload.user.name,
                phone: payload.user.phone,
                city: payload.user.city,
                directPayment: payload.user.directPayment,
                // role is sent separately because it is not part of the user object in the database
                role: payload.role,
            };
        case LOAD_USER_PROFILE_FAIL:
            return {
                ...state,
                id: '0',
                username: '',
                email:'',
                name: '',
                phone: '',
                city: '',
                role: '',
            };
        case UPDATE_USER_PROFILE_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
};

import axios from "axios";
import {LOAD_USER_PROFILE_SUCCESS, LOAD_USER_PROFILE_FAIL, UPDATE_USER_PROFILE_SUCCESS} from "./types";
import {Dispatch} from "@reduxjs/toolkit";
import {Config} from "../types/globals";

const API_BASE_URL = Config.API_BASE_URL;

axios.defaults.withCredentials = true;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const load_user = () => async (dispatch: Dispatch) => {
  console.log("loading user")
  try {
    const res = await axios.get(`${API_BASE_URL}/user/session`);

    if (res.data.error) {
      dispatch({
        type: LOAD_USER_PROFILE_FAIL,
      });
    } else {
      dispatch({
        type: LOAD_USER_PROFILE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LOAD_USER_PROFILE_FAIL,
    });
  }
};

export const loadProfile = (user) => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: user
  };
};

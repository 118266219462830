import React, { useState } from 'react';

const Carousel = ({ media, onImageClick, onVideoClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === media.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? media.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative">
      <div className="flex overflow-hidden cursor-pointer">
        {media[currentIndex].endsWith('.mp4') || media[currentIndex].endsWith('.webm') ? (
          <video
            src={media[currentIndex]}
            controls
            className="block w-full h-auto"
            onClick={() => onVideoClick(media[currentIndex])}
          />
        ) : (
          <img
            src={media[currentIndex]}
            alt={`Slide ${currentIndex}`}
            className="block w-full h-auto"
            onClick={() => onImageClick(media[currentIndex])}
          />
        )}
      </div>
      <button
        onClick={prev}
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white bg-opacity-75 rounded-full p-2 ml-2 text-black"
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6">
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
      </button>
      <button
        onClick={next}
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white bg-opacity-75 rounded-full p-2 mr-2 text-black"
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6">
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </button>
    </div>
  );
};

export default Carousel;

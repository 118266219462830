import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "../actions/types";
import {PayloadAction} from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: null,
  isLoading: true,
  isError: false,
};

const typeMatching = (
  state = initialState,
  action: PayloadAction<{ isAuthenticated: boolean; isLoading: boolean; error?: string }>
) => {
  const { type, payload } = action;

  switch (type) {
    case AUTHENTICATED_SUCCESS:
    case AUTHENTICATED_FAIL:
      return {
        ...state,
        isAuthenticated: payload,
        isLoading: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        error: null, // Reset error
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        error: null, // Reset error
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        error: payload?.error || 'Login failed. Please try again.',
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        error: null, // Reset error
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        error: null, // Reset error
      };
    case REGISTER_FAIL:
      return {
        ...state,
        error: payload?.error || 'Registration failed. Please try again.',
      };
    case LOGOUT_FAIL:
      return state;
    case DELETE_USER_FAIL:
      return state;
    default:
      return state;
  }
};

export default typeMatching;

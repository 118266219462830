import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getWorkItems, approveQuote, createPaymentIntent } from "../backend";
import { addWorkItem, updateWorkItemStatus } from "../actions/workItems";
import { RootState } from "../store";
import Modal from "../components/Modal";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Profile, Quote, WorkItem } from "../types/types";
import { statusMappingLandlord, WorkItemStatus, Config, WorkTypes } from '../types/globals'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../components/PaymentForm";
import Logout from "../components/Logout";
import { formatQuote, getQuoteAmount } from '../utlis';
import Carousel from '../components/Carousel';
import { MoonLoader } from 'react-spinners';

const stripePromise = loadStripe(Config.STRIPE_PUBLIC_KEY);

const LandlordPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const workItems = useAppSelector((state: RootState) => state.workItems.workItems);
  const user: Profile = useAppSelector((state: RootState) => state.profile);
  const [selectedWorkItem, setSelectedWorkItem] = useState<WorkItem | null>(null);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [selectedQuote, setSelectedQuote] = useState<Quote | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState("");

  useEffect(() => {
    const fetchWorkItems = async () => {
      const items = await getWorkItems('landlord', user.email);
      items.forEach((item) => {
        dispatch(addWorkItem(item));
      });
    };
    if (user.email) {
      fetchWorkItems();
    }
  }, [dispatch, user.email]);

  const handleSelectQuote = async (workItem: WorkItem, quote: Quote) => {
    if (!user.directPayment) {
      setSelectedQuote(quote);
      if (!selectedWorkItem) return;
      const updatedItem = await approveQuote(selectedWorkItem.id, quote.id, quote.contractor.id);
      if (updatedItem) {
        dispatch(updateWorkItemStatus(updatedItem));
        setSelectedWorkItem(null);
      }
      return;
    }
    const quoteAmount = getQuoteAmount(quote);

    if (isNaN(quoteAmount)) {
      console.error('Invalid quote amount:', quote.quote);
      return;
    }

    const amount = Math.round(quoteAmount * 100);
    try {
      setIsLoading(true);
      const clientSecret = await createPaymentIntent(amount);
      setClientSecret(clientSecret);
      setSelectedWorkItem(workItem);
      setSelectedQuote(quote);
      setPaymentAmount(amount);
    } catch (error) {
      console.error('Error creating payment intent:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = async () => {
    if (!selectedWorkItem) return;

    const updatedItem = await approveQuote(selectedWorkItem.id, selectedQuote.id, selectedQuote.contractor.id);
    if (updatedItem) {
      dispatch(updateWorkItemStatus(updatedItem));
      setSelectedWorkItem(null);
      setClientSecret("");
      setPaymentAmount(0);
    }
  };

  const handlePictureClick = (photoUrl: string) => {
    setSelectedPhoto(photoUrl);
    setIsImageModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsImageModalOpen(false);
  };

  const isQuotesAvailable = (status: WorkItemStatus) => {
    return status === WorkItemStatus.QUOTE_SENT_TO_LANDLORD;
  };

  return (
    <div>
      <header className="flex justify-between items-center p-4 bg-gray-50 border-b border-gray-200">
        <Link to="/landlord">
          <div className="flex items-center">
            <img src="/favicon.png" className="h-6 mr-3 sm:h-9" alt="Coflow Logo" />
            <span className="self-center text-xl font-semibold whitespace-nowrap">Coflow Landlord Dashboard</span>
          </div>
        </Link>
        <div className="text-gray-700">Hello, {user?.name || "Landlord"}</div>
        <Logout />
      </header>
      <div className="p-4">
        <h1 className="text-2xl mb-4">Ongoing Works at your properties</h1>
        {workItems.map((item) => (
          <div key={item.id} className="mb-4 p-4 border border-gray-200 rounded">
            <div>{item.property?.address || "No Address"}</div>
            <div>{WorkTypes[item.workItemType] || item.workItemType}</div>
            <div className="flex items-center mb-2">
              <span className={`mr-2 ${isQuotesAvailable(item.status) ? 'bg-yellow-300' : 'bg-gray-300'} px-2 py-1 rounded text-sm`}>
                {statusMappingLandlord[item.status]}
              </span>
              {isQuotesAvailable(item.status) && (
                <span className="px-2 py-1 rounded bg-gray-200 text-sm">Please review</span>
              )}
            </div>
            {isQuotesAvailable(item.status) && (
              <button
                onClick={() => isQuotesAvailable(item.status) && setSelectedWorkItem(item)}
                className={`px-4 py-2 text-white rounded ${isQuotesAvailable(item.status) ? 'bg-blue-500' : 'bg-gray-500 cursor-not-allowed'}`}
              >
                Review Quotes
              </button>
            )}
          </div>
        ))}
      </div>
      {selectedWorkItem && (
        <Modal>
          <div className="fixed top-0 right-0 w-full h-full bg-white shadow-lg p-4 overflow-auto">
            {isLoading && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <MoonLoader color={"#7E22CE"} size={50} />
              </div>
            )}
            <button
              className="bg-gray-500 text-white px-4 py-2 mb-4 rounded"
              onClick={() => setSelectedWorkItem(null)}
            >
              Back
            </button>
            <h2 className="text-2xl mb-4">{selectedWorkItem.property?.address || "No Address"}</h2>
            <div className="mb-4">Report type: {selectedWorkItem.workItemType}</div>
            <div className="mb-4">Reported date: {new Date(selectedWorkItem.createdAt).toLocaleDateString()}</div>
            <div className="mt-4 p-4 bg-gray-100 border border-gray-200">
              <span className="font-bold">Report summary:</span>
              <p>{selectedWorkItem.description}</p>
              {selectedWorkItem.photos && selectedWorkItem.photos.length > 0 && (
                <div className="mt-4 mx-auto max-w-[500px] w-full">
                  <Carousel media={selectedWorkItem.photos} onImageClick={handlePictureClick} onVideoClick={null} />
                </div>
              )}
            </div>
            {selectedWorkItem.quotes && selectedWorkItem.quotes.length > 0 ? (
              <div className="mt-4">
                {selectedWorkItem.quotes.map((quote) => (
                  <div key={quote.id} className="p-4 border border-gray-300 rounded mb-4">
                    <div className="mb-2"><span className="font-bold">Contractor:</span> {quote.contractor.name}</div>
                    <div className="mb-2"><span className="font-bold">Estimated Quote (excl. VAT):</span> {formatQuote(quote, false)}</div>
                    <div className="mb-2"><span className="font-bold">Note:</span> <span className="whitespace-normal break-words">{quote.note}</span></div>
                    <div className="mb-2"><span className="font-bold">Lead Time Details:</span> {quote.leadTimeDetails}</div>
                    <div className="text-right">
                      <button onClick={() => handleSelectQuote(selectedWorkItem, quote)} className="px-4 py-2 bg-green-500 text-white rounded">
                        Approve Quote
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mb-4">No quotes available</div>
            )}
            {clientSecret && (
              <div className="mt-4">
                <Elements stripe={stripePromise}>
                  <PaymentForm clientSecret={clientSecret} amount={paymentAmount} onPaymentSuccess={handlePaymentSuccess} contractorName={selectedQuote.contractor.name} />
                </Elements>
              </div>
            )}
            <button onClick={() => setSelectedWorkItem(null)} className="px-4 py-2 bg-gray-500 text-white rounded mt-4">Close</button>
          </div>
        </Modal>
      )}
      {isImageModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          onClick={handleCloseModal}  // Close modal on any click
        >
          <div className="bg-white p-4 rounded shadow-lg">
            <img src={selectedPhoto} alt="Report" />
          </div>
        </div>
      )}
    </div>
  );
};

export default LandlordPage;

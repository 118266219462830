import React, { useState } from 'react';
import { Property } from '../../types/types';
import { createProperty, updateProperty } from '../../backend';
import { MoonLoader } from 'react-spinners';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '../Modal';
import PMPropertyForm from '../creationForm/PMPropertyForm';

const PMPropertiesView = ({ properties, landlords, propertyManagerId, onAddProperty, onUpdateProperty }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingPropertyId, setEditingPropertyId] = useState<string | null>(null);

  const [thisProperty, setThisProperty] = useState<Omit<Property, 'id' | 'propertyManager' | 'workItems' | 'landlord'>>({
    address: '',
    postCode: '',
    accessDescription: '',
    tenant: undefined,
    propertyManagerId: propertyManagerId,
    landlordId: '',
    defaultThreshold: 300,
  });

  const [formErrorMessage, setFormErrorMessage] =  useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setThisProperty({ ...thisProperty, [name]: value });
  };

  const handleClose =  async () => {
    setThisProperty({
      address: '',
      postCode: '',
      accessDescription: '',
      tenant: undefined,
      propertyManagerId: propertyManagerId,
      landlordId: '',
      defaultThreshold: 300,
    })
    setFormErrorMessage('')
    setIsModalOpen(false)
  }

  const handleSubmit = async () => {
    setIsLoading(true);

    // validating fields before proceeding
    if (!thisProperty.address || !thisProperty.postCode || !thisProperty.landlordId || !thisProperty.defaultThreshold) {
      setFormErrorMessage('Please fill out all required fields');
      setIsLoading(false);
      return
    }

    try {
      let response;
      if (isEditMode && editingPropertyId !== null) {
        // Update existing property
        response = await updateProperty(editingPropertyId, thisProperty);
        onUpdateProperty(response);
      } else {
        // Create new property
        response = await createProperty(thisProperty);
        onAddProperty(response);
      }

      if (response.error) {
        console.error('Error:', response.error);
        setFormErrorMessage(response.error)
        return;
      }

      setIsModalOpen(false);
      setEditingPropertyId(null);
      setIsEditMode(false);
    } catch (error) {
      console.error('Error submitting property:', error);
      setFormErrorMessage('Error submitting property. Please try again later')
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (property: Property) => {
    setThisProperty({
      address: property.address,
      postCode: property.postCode,
      accessDescription: property.accessDescription,
      tenant: property.tenant,
      propertyManagerId: property.propertyManagerId,
      landlordId: property.landlordId,
      defaultThreshold: property.defaultThreshold,
    });
    setEditingPropertyId(property.id);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  return (
    <div className="h-full">
      <div className="flex justify-between items-center mb-4 sticky top-0 z-10">
        <h2 className="text-2xl">Properties Panel</h2>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
          onClick={() => {
            setThisProperty({
              address: '',
              postCode: '',
              accessDescription: '',
              tenant: undefined,
              propertyManagerId: propertyManagerId,
              landlordId: '',
              defaultThreshold: 300,
            });
            setIsEditMode(false);
            setIsModalOpen(true);
          }}
        >
          <span className="mr-2">+</span> Add Property
        </button>
      </div>
      <div className="overflow-y-auto h-[calc(100vh-150px)]">
        <div className="grid grid-cols-1 gap-4">
          {properties.map((property) => (
            <div
              key={property.id}
              className="p-4 border rounded shadow group hover:bg-gray-100 transition-colors duration-200"
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <span className="font-bold">{property.address}, {property.postCode}</span>
                  <button
                    className="text-blue-500 ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                    onClick={() => handleEditClick(property)}
                  >
                    <EditIcon />
                  </button>
                </div>
              </div>
              <span className="text-gray-500">
                {property.tenant ? property.tenant.name : '(unoccupied)'}
                {property.tenant ? ' (' + property.tenant.email + ')' : ''}
              </span>
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <Modal>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <MoonLoader color="#7E22CE" size={50} />
            </div>
          ) : (
            <PMPropertyForm
              landlords={landlords}
              newProperty={thisProperty}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
              errorMessage={formErrorMessage}
              isEditMode={isEditMode}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default PMPropertiesView;

import { WorkItem } from '../types/types'

export const ADD_WORK_ITEM = 'ADD_WORK_ITEM';
export const UPDATE_WORK_ITEM_STATUS = 'UPDATE_WORK_ITEM_STATUS';
export const SET_SELECTED_WORK_ITEM = 'SET_SELECTED_WORK_ITEM';
export const SET_DESELECTED_WORK_ITEM = 'SET_DESELECTED_WORK_ITEM';

export const addWorkItem = (workItem: WorkItem) => ({
  type: ADD_WORK_ITEM,
  post: workItem
});

export const updateWorkItemStatus = (workItem: WorkItem) => ({
  type: UPDATE_WORK_ITEM_STATUS,
  post: workItem
});

export const setSelectedWorkItem = (workItem: WorkItem) => ({
  type: SET_SELECTED_WORK_ITEM,
  post: workItem
});

export const deselectedWorkItem = (workItem: WorkItem) => ({
  type: SET_DESELECTED_WORK_ITEM,
  post: workItem
});

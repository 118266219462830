import axios from "axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL, LOAD_USER_PROFILE_SUCCESS,
} from "./types";
import { Dispatch } from "@reduxjs/toolkit";
import { Config } from "../types/globals";


const API_BASE_URL = Config.API_BASE_URL;

axios.defaults.withCredentials = true;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const checkAuthenticated = () => async (dispatch: Dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`${API_BASE_URL}/user/authenticated`, config);

    if (res.data.error || res.data.isAuthenticated === "error") {
      dispatch({
        type: AUTHENTICATED_FAIL,
        payload: false,
      });
    } else if (res.data.isAuthenticated === "success") {
      dispatch({
        type: AUTHENTICATED_SUCCESS,
        payload: true,
      });
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
        payload: false,
      });
    }
  } catch (err) {
    dispatch({
      type: AUTHENTICATED_FAIL,
      payload: false,
    });
  }
};

export const login = (email: string, password: string, role: string) => async (dispatch: Dispatch) => {
  const body = JSON.stringify({ email, password, role });

  try {
    const res = await axios.post(`${API_BASE_URL}/user/login`, body);
    if (res.status === 200) {
      dispatch({
        type: LOGIN_SUCCESS,
      });
      dispatch({
        type: LOAD_USER_PROFILE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: {error: 'Login failed. Please try again.'}
      });
    }
  } catch (err) {
    console.log('error: ', err)
    dispatch({
      type: LOGIN_FAIL,
      payload: {error: err.response?.data?.message || 'Login failed. Please try again.'}
    });
  }
};


export const register =
  (email: string, password: string, name: string, role: string, address: string, postCode: string, phone: string) => async (dispatch: Dispatch) => {
    const body = JSON.stringify({
      email,
      password,
      name,
      role,
      address,
      postCode,
      phone
    });
    try {
      const res = await axios.post(`${API_BASE_URL}/user/register`, body);
      if (res.data.error) {
        dispatch({
          type: REGISTER_FAIL,
        });
        return res;
      } else {
        dispatch({
          type: REGISTER_SUCCESS,
        });
        return res;
      }
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
      });
      throw err;
    }
  };

export const logout = () => async (dispatch: Dispatch) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/user/logout`);
    if (res.data.success) {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    } else {
      dispatch({
        type: LOGOUT_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: LOGOUT_FAIL,
    });
  }
};

// Special logout for SignUp page that always returns LOGOUT_SUCESS to ensure user got forwarded to login after SignUp
export const autolLogout = () => (dispatch) => {
  try {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userProfile');

    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {
    console.log(error)
  }
};


export const delete_account = () => async (dispatch: Dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(`${API_BASE_URL}/user/delete`, config);

    if (res.data.success) {
      dispatch({
        type: DELETE_USER_SUCCESS,
      });
    } else {
      dispatch({
        type: DELETE_USER_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: DELETE_USER_FAIL,
    });
  }
};

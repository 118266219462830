import { Config } from './types/globals'
import { WorkItem } from './types/types'
import axios from "axios"

const API_BASE_URL = Config.API_BASE_URL;

export const getWorkItems = async (user: string, email: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/workItem?user=${user}&email=${email}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (!Array.isArray(data)) {
      throw new Error('Response is not an array');
    }

    return data;
  } catch (error) {
    console.error('Error fetching work items:', error);
    return [];
  }
};

export const createProperty = async (data) => {
  console.log('Creating Property:', data);
  const result = await fetch(`${API_BASE_URL}/property/new`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify(data)
  });
  return await result.json();
};

export const updateProperty = async (propertyId, updatedData) => {
  console.log(`Updating Property ${propertyId}:`, updatedData);
  try {
    const response = await fetch(`${API_BASE_URL}/property/update/${propertyId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(updatedData),
    });
    return await response.json();
  } catch (error) {
    console.error('Error updating property:', error);
    throw error;
  }
};

export const getAllPropertiesByPM = async (PropertyManagerId: string) => {
  return await fetch(`${API_BASE_URL}/property/pm/${PropertyManagerId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((data) => data.json());
};

export const getPropertiesWithoutTenantByPM = async (propertyManagerId: string) => {
  try {

    console.log("getPropertiesWithoutTenantByPM")

    const response = await fetch(`${API_BASE_URL}/property/withoutTenant/${propertyManagerId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }
    });

    if (!response.ok) {
      console.log(response)
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (!Array.isArray(data)) {
      throw new Error('Response is not an array');
    }

    console.log("Properties without tenant: ", response)

    return data;
  } catch (error) {
    console.error('Error fetching properties without tenant:', error);
    return [];
  }
};

export const saveWorkItem = async (data: WorkItem) => {
  return await fetch(`${API_BASE_URL}/workItem`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(data)
  }).then((data) => data.json());
};

export const updateWorkItem = async (id: string, status: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/workItem`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ id: id, status: status }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error approving quote:', error);
    return null;
  }
};

export const markRemovedWorkItem = async (workItemId: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/workitem/remove`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ workItemId }),
    });

    if (!response.ok) {
      throw new Error('Failed to delete work item');
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting work item:', error);
    throw error;
  }
};

export const createWorkItem = async (data: WorkItem) => {
  console.log('Creating Work Item:', data)
  const result = await fetch(`${API_BASE_URL}/workItem/new`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify(data),
    credentials: 'include'
  })
  return await result.json()
};

export const getTenantByEmail = async (email: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/tenant/email/${email}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching tenant by email:', error);
    throw error;
  }
};

export const getTenantsByPM = async (propertyManagerId: string) => {
  console.log("getTenantsByPM: ", propertyManagerId)
  return await fetch(`${API_BASE_URL}/tenants/pm/${propertyManagerId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((data) => data.json());
};

export const createTenant = async (tenant) => {
  const response = await fetch(`${API_BASE_URL}/tenant/new`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tenant),
  });
  return await response.json();
};

export const updateTenant = async (tenantId, updatedData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/tenant/update/${tenantId}`, {
      method: 'PUT',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(updatedData),
    });
    return await response.json();
  } catch (error) {
    console.error('Error updating tenant:', error);
    throw error;
  }
};

export const approveQuote = async (workItemId: string, quoteId: string, contractorId: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/quote/approve`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ workItemId, quoteId, contractorId }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error approving quote:', error);
    return null;
  }
};

export const rejectQuote = async (workItemId: string, quoteId: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/quote/reject`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        workItemId,
        quoteId,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to reject quote');
    }

    return await response.json();
  } catch (error) {
    console.error('Error rejecting quote:', error);
    throw error;
  }
};

export const createPaymentIntent = async (amount: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}/payment/pay`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.clientSecret;
  } catch (error) {
    console.error('Error creating payment intent:', error);
    throw error;
  }
};

export const getLandlordsByPM = async (pmId: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/landlords/${pmId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching landlords by property manager ID:', error);
    throw error;
  }
};

export const createLandlord = async (landlordData, propertyManagerId) => {
  const response = await fetch(`${API_BASE_URL}/landlords/new`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({ ...landlordData, propertyManagerId }),
  });
  if (response.status === 500) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return await response.json();
};

export const updateLandlord = async (landlordId, updatedData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/landlords/update/${landlordId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(updatedData),
    });
    return await response.json();
  } catch (error) {
    console.error('Error updating landlord:', error);
    throw error;
  }
};

export const fetchContractorWorkItemPageInfo = async (workItemId, contractorId) => {
  console.log(`Fetching Contractor Work Item Page Info for WorkItem ${workItemId} by Contractor ${contractorId}`);
  const response = await fetch(
    `${API_BASE_URL}/workItem/${workItemId}/contractor/${contractorId}/page-info`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
  
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error || 'Error fetching contractor work item page info');
  }

  return await response.json();
};

export const getContractorWorkItem = async (workItemId: string, contractorId: string) => {
  console.log(`Fetching WorkItem ${workItemId} for Contractor ${contractorId}`);
  const response = await fetch(
    `${API_BASE_URL}/workItem/${workItemId}/contractor/${contractorId}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }
  );
  return await response.json();
};

export const getContractor = async (contractorId: string) => {
  console.log(`Fetching Contractor with ID: ${contractorId}`);
  try {
    const response = await fetch(`${API_BASE_URL}/contractor/${contractorId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching contractor: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching contractor by ID:', error);
    throw error;
  }
};


export const submitContractorQuote = async (workItemId, contractorId, data) => {
  console.log(`Submitting Quote for WorkItem ${workItemId} by Contractor ${contractorId}`);
  const response = await fetch(
    `${API_BASE_URL}/workItem/${workItemId}/contractor/${contractorId}/quote`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(data),
    }
  );
  return await response.json();
};

export const uploadRepairSummary = async (workItemId, contractorId, data) => {
  console.log(`Uploading Repair Summary for WorkItem ${workItemId} by Contractor ${contractorId}`);
  const response = await fetch(
    `${API_BASE_URL}/workItem/${workItemId}/contractor/${contractorId}/repair-summary`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(data),
    }
  );
  return await response.json();
};


export const loadUser = async () => {
  try {
    const res = await axios.get(`${API_BASE_URL}/user/session`);
    if (res.data.error) {
      return null
    } else {
      return res.data
    }
  } catch (error) {
    console.error('Error Getting User STATE:', error);
    return null
  }
};

import { combineReducers } from 'redux';
import auth from './auth';
import profile from './profile';
import workItems from './workItems';

export default combineReducers({
    auth,
    profile,
    workItems
});

import React, { useState } from 'react';
import { Tenant } from '../../types/types';
import { createTenant, updateTenant } from '../../backend';
import { MoonLoader } from 'react-spinners';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '../Modal';
import PMTenantForm from '../creationForm/PMTenantForm';

const PMTenantsView = ({ tenants, properties, onAddTenant, onUpdateTenant }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingTenantId, setEditingTenantId] = useState<string | null>(null);

  const [thisTenant, setThisTenant] = useState<Omit<Tenant, 'id' | 'property'> & { password: string }>({
    name: '',
    email: '',
    phone: '',
    preferredCommunication: 'ANY',
    propertyId: '',
    password: '',
    secondaryName: '',
    secondaryEmail: '',
    secondaryPhone: ''
  });

  const [formErrorMEssage, setFormErrorMessage] = useState('')

  const handleClose = async () => {
    // reset current Tenant value -> Form will be emptied out after closure
    setThisTenant({
      name: '',
      email:'',
      phone: '',
      preferredCommunication: 'ANY',
      propertyId: '',
      password: '',
      secondaryName: '',
      secondaryEmail: '',
      secondaryPhone: ''
    })
    setFormErrorMessage('')
    setIsModalOpen(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setThisTenant({ ...thisTenant, [name]: value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    // validating fields before proceeding
    console.log('isEditMode', isEditMode)
    console.log()
    if (
      (!thisTenant.name || !thisTenant.email || !thisTenant.phone || !thisTenant.preferredCommunication)
      // addutional constraints for Create mode
      || (!isEditMode && (!thisTenant.propertyId || !thisTenant.password))) {
      setFormErrorMessage('Please fill out all required fields');
      setIsLoading(false);
      return
    }

    try {
      let response;
      if (isEditMode && editingTenantId !== null) {
        // Update existing tenant
        response = await updateTenant(editingTenantId, thisTenant);
      } else {
        // Create new tenant
        response = await createTenant(thisTenant);
      }

      if (response.error) {
        console.error('Error on handleSubmit: ', response.error);
        setFormErrorMessage(response.error);
        return;
      }

      if (isEditMode && editingTenantId !== null) {
        onUpdateTenant(response);
      } else {
        onAddTenant(response, thisTenant.propertyId);
      }

      setIsModalOpen(false);
      setEditingTenantId(null);
      setIsEditMode(false);
    } catch (error) {
      console.error('Error creating/updating tenant:', error);
      setFormErrorMessage('Error submitting property. Please try again later')
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (tenant: Tenant) => {
    setThisTenant({
      name: tenant.name,
      email: tenant.email,
      phone: tenant.phone,
      preferredCommunication: tenant.preferredCommunication,
      propertyId: tenant.propertyId,
      password: '',
      secondaryName: tenant.secondaryName,
      secondaryEmail: tenant.secondaryEmail,
      secondaryPhone: tenant.secondaryPhone
    });
    setEditingTenantId(tenant.id);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  return (
    <div className="h-full">
      <div className="flex justify-between items-center mb-4 sticky top-0 z-10">
        <h2 className="text-2xl">Tenants Panel</h2>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
          onClick={() => {
            setThisTenant({
              name: '',
              email: '',
              phone: '',
              preferredCommunication: 'ANY',
              propertyId: '',
              password: '',
            });
            setIsEditMode(false);
            setIsModalOpen(true);
          }}
        >
          <span className="mr-2">+</span> Add Tenant
        </button>
      </div>
      <div className="overflow-y-auto h-[calc(100vh-150px)]">
        <div className="grid grid-cols-1 gap-4">
          {tenants.map((tenant) => (
            <div
              key={tenant.id}
              className="p-4 border rounded shadow group hover:bg-gray-100 transition-colors duration-200"
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <span className="font-bold">{tenant.name}</span>
                  <button
                    className="text-blue-500 ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                    onClick={() => handleEditClick(tenant)}
                  >
                    <EditIcon />
                  </button>
                </div>
                <span className="text-gray-500">{tenant.email}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500">{tenant.property ? tenant.property.address : '(unoccupied)'}</span>
                <span className="text-gray-500">{tenant.phone}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <Modal>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <MoonLoader color="#7E22CE" size={50} />
            </div>
          ) : (
            <PMTenantForm
              properties={properties}
              newTenant={thisTenant}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
              errorMessage={formErrorMEssage}
              isEditMode={isEditMode} // Pass edit mode status
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default PMTenantsView;

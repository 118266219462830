import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import MoonLoader from "react-spinners/MoonLoader";
import * as generator from "generate-password-browser";
import { useAppSelector } from "../hooks";
import { autolLogout, register } from "../actions/auth";
import Modal from "../components/Modal";

function SignUp({ register, autolLogout }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [redirectPath, setRedirectPath] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    address: "",
    name: "",
    role: "",
    postCode: "",
    phone: "",
  });

  const user = useAppSelector((store) => store.profile);
  const { email, password, name, role, address, postCode, phone } = formData;

  useEffect(() => {
    if (user.email.length > 0) {
      const password = generator.generate({
        length: 10,
        numbers: true
      });
      setFormData({ ...formData, email: user.email, password: password });
      setPage(2);
      console.log("FormData:", formData);
    }
  }, [user, formData]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateInput()) return;

    if (page === 1) {
      setPage(2);
    } else {
      setLoading(true);

      console.log("Registering account: ", [email, password, name, role, address, postCode, phone]);

      try {
        // Register the new user and get the response
        const registerResponse = await register(email, password, name, role, address, postCode, phone);

        // Check if registration was successful
        if (registerResponse.data.error) {
          throw new Error("Registration failed");
        }

        // Auto logout to clear any existing session
        autolLogout();

        setModalMessage("Account registered successfully");
        setRedirectPath("/login");
      } catch (err) {
        console.error("Registration or logout failed:", err);
        setModalMessage("Can not create a new account. Try again later");
        setRedirectPath("/signup");
      }

      setLoading(false);
      setShowModal(true);
    }
  };

  const validateInput = () => {
    if (page === 1) {
      if (email.length <= 0 || password.length < 8) {
        setErrorMessage("Please enter a valid email and password. Password must be at least 8 characters long.");
        return false;
      }
    } else if (page === 2) {
      if (name === undefined || address === undefined || postCode === undefined || phone === undefined) {
        setErrorMessage("Please fill out all the fields");
        return false;
      }
    }
    setErrorMessage("");
    return true;
  };

  const handleModalClose = () => {
    console.log("Redirect path: ", redirectPath)
    setShowModal(false);
    navigate(redirectPath);
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(249, 250, 251)",
      }}
    >
      <div
        className="flex h-16 shrink-0 items-center px-6"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          cursor: "pointer",
        }}
        onClick={() => (window.location.href = "https://www.joincoflow.com/")}
      >
        <img className="h-8 w-auto" src="logo.svg" alt="Coflow" />
        <span className="self-center text-xl font-semibold whitespace-nowrap pl-2">
          Coflow
        </span>
      </div>
      {loading ? (
        <div style={{ width: "fit-content", height: "fit-content" }}>
          <MoonLoader color={"#7E22CE"} loading={true} size={50} />
        </div>
      ) : (
        <>
          <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              {page === 1 && (
                <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Try our beta version today{" "}
                  <img
                    src="https://em-content.zobj.net/source/apple/354/sparkles_2728.png"
                    alt="robot"
                    style={{
                      display: "inline-block",
                      width: "1.7rem",
                      height: "1.7rem",
                      verticalAlign: "top",
                      lineHeight: "1.7rem",
                      marginTop: "4px",
                    }}
                  />
                </h2>
              )}
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
              <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                <h1 className="mt-2 mb-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign Up as Property Manager</h1>
                <form className="space-y-6">
                  {page === 2 && (
                    <h2 className="pt-2 text-2xl pb-4 font-bold tracking-tight text-gray-900">
                      Tell us more about your firm!
                    </h2>
                  )}
                  <div
                    style={
                      page === 1
                        ? {}
                        : {
                          visibility: "hidden",
                          display: "none",
                        }
                    }
                  >
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Your Email
                    </label>
                    <div className="mt-2">
                      <input
                        style={{
                          backgroundColor: "rgb(249, 250, 251)",
                        }}
                        placeholder="email@example.com"
                        id="email"
                        type="text"
                        autoFocus
                        name="email"
                        onChange={(e) => onChange(e)}
                        value={email}
                        required
                        className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-700 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div
                    style={
                      page === 1
                        ? {}
                        : {
                          visibility: "hidden",
                          display: "none",
                        }
                    }
                  >
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-4 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        style={{
                          backgroundColor: "rgb(249, 250, 251)",
                        }}
                        placeholder="**********"
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        className="block w-full rounded-md border-0  py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-700 sm:text-sm sm:leading-6"
                        onChange={(e) => onChange(e)}
                        value={password}
                        minLength={8}
                        required
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            onSubmit(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={
                      page === 2
                        ? {}
                        : {
                          visibility: "hidden",
                          display: "none",
                        }
                    }
                  >
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Your Organization
                    </label>
                    <div className="mt-2">
                      <input
                        style={{
                          backgroundColor: "rgb(249, 250, 251)",
                        }}
                        placeholder="Big Properties Enterprise Co."
                        id="name"
                        type="text"
                        name="name"
                        onChange={(e) => onChange(e)}
                        value={name}
                        required
                        className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-700 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div
                    style={
                      page === 2
                        ? {}
                        : {
                          visibility: "hidden",
                          display: "none",
                        }
                    }
                  >
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Phone Number
                    </label>
                    <div className="mt-2">
                      <input
                        style={{
                          backgroundColor: "rgb(249, 250, 251)",
                        }}
                        placeholder="07123 456789"
                        id="phone"
                        type="text"
                        name="phone"
                        onChange={(e) => onChange(e)}
                        value={phone}
                        required
                        className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-700 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div
                    style={
                      page === 2
                        ? {}
                        : {
                          visibility: "hidden",
                          display: "none",
                        }
                    }
                  >
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium leading-4 text-gray-900"
                    >
                      Address
                    </label>
                    <div className="mt-2">
                      <input
                        style={{
                          backgroundColor: "rgb(249, 250, 251)",
                        }}
                        id="address"
                        name="address"
                        type="text"
                        placeholder="123 Baker Street, London, NW1 6XE, United Kingdom"
                        className="block w-full rounded-md border-0  py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-700 sm:text-sm sm:leading-6"
                        onChange={(e) => onChange(e)}
                        value={address}
                        required
                      />
                    </div>
                  </div>
                  <div
                    style={
                      page === 2
                        ? {}
                        : {
                          visibility: "hidden",
                          display: "none",
                        }
                    }
                  >
                    <label
                      htmlFor="postCode"
                      className="block text-sm font-medium leading-4 text-gray-900"
                    >
                      Post Code
                    </label>
                    <div className="mt-2">
                      <input
                        style={{
                          backgroundColor: "rgb(249, 250, 251)",
                        }}
                        id="postCode"
                        name="postCode"
                        type="text"
                        placeholder="NW1 6XE"
                        className="block w-full rounded-md border-0  py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-700 sm:text-sm sm:leading-6"
                        onChange={(e) => onChange(e)}
                        value={postCode}
                        required
                      />
                    </div>
                  </div>
                  <br />
                  {errorMessage && <p className="mt-16 text-sm text-red-500">{errorMessage}</p>}
                  <button
                    onClick={(e) => onSubmit(e)}
                    className="flex w-full justify-center rounded-md bg-purple-700 px-3 py-2  text-sm font-semibold text-white shadow-sm hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-700"
                  >
                    {loading
                      ? "Loading..."
                      : page === 1
                        ? "Continue"
                        : "Try it now"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      {showModal && (
        <Modal>
          <p className="mb-4 text-center">{modalMessage}</p>
          <button
            onClick={handleModalClose}
            className="block w-full justify-center rounded-md bg-purple-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-700"
          >
            OK
          </button>
        </Modal>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, { register, autolLogout })(SignUp);

import React from "react";
import { connect } from "react-redux";
import { logout } from "../actions/auth";


interface LogoutDispatchProps {
    logout: () => void
}
const Logout = ( props: LogoutDispatchProps ) => {
  const onSubmit = () => {
    const userLogout = async () => {
      await props.logout();
      window.location.reload();
    };
    userLogout();
  };

  return (
    <>
      <button
        className="text-sm bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 m-2 rounded inline-flex items-center"
        onClick={() => {
          onSubmit();
        }}
      >
        Logout
      </button>
    </>
  );
};


//Short hand for mapDispatchToProps
const mapDispatchToProps: LogoutDispatchProps = {
    logout
}

export default connect(null, mapDispatchToProps)(Logout);

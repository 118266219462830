import { ADD_WORK_ITEM, UPDATE_WORK_ITEM_STATUS, SET_SELECTED_WORK_ITEM, SET_DESELECTED_WORK_ITEM } from '../actions/workItems';
import { WorkItem } from '../types/types'

const initialState = {
  workItems: [],
  selectedWorkItem: 0,
};

export default function workItems(state = initialState, action: { type: string, post: WorkItem }) {
  switch (action.type) {
    case ADD_WORK_ITEM:
      // Check if the item already exists in the state. If it exists, return the current state without adding
      if (state.workItems.some(item => item.id === action.post.id)) {
        return state;
      }
      return {
        ...state,
        workItems: [...state.workItems, action.post],
      };
    case UPDATE_WORK_ITEM_STATUS:
      return {
        ...state,
        workItems: state.workItems.map(item =>
          item.id === action.post.id ? { ...item, status: action.post.status } : item
        ),
      };
    case SET_SELECTED_WORK_ITEM:
      return { ...state, selectedWorkItem: action.post.id };
    case SET_DESELECTED_WORK_ITEM:
      return { ...state, selectedWorkItem: 0 };
    default:
      return state;
  }
}

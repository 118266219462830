import React, { useEffect, useState } from 'react';
import { updateWorkItemStatus } from '../actions/workItems';
import { approveQuote, createPaymentIntent, rejectQuote, updateWorkItem } from '../backend';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Quote } from '../types/types';
import { Config, footerMessages, statusActionsPropertyManager, WorkItemStatus, WorkTypes } from '../types/globals';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../components/PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import Carousel from '../components/Carousel';
import { MoonLoader } from 'react-spinners';
import Modal from '../components/Modal';
import { formatQuote, getQuoteAmount } from '../utlis';

const stripePromise = loadStripe(Config.STRIPE_PUBLIC_KEY);

const WorkItemDetail = ({ workItem, onClose, onUpdate }) => {
  const [selectedWorkItem, setSelectedWorkItem] = useState(workItem);
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const dispatch = useAppDispatch();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [selectedQuote, setSelectedQuote] = useState<Quote | null>(null);
  const directPayment = useAppSelector((store) => store.profile.directPayment);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [pendingQuote, setPendingQuote] = useState<Quote | null>(null);
  const [isRejectConfirmModalOpen, setIsRejectConfirmModalOpen] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false); // Tooltip visibility state

  useEffect(() => {
    setSelectedWorkItem(workItem);
  }, [workItem]);

  const handleActionClick = (newStatus: WorkItemStatus) => {
    setIsLoading(true);
    updateWorkItem(selectedWorkItem.id, newStatus)
      .then((updatedWorkItem) => {
        if (updatedWorkItem) {
          console.log("updatedWorkItem: ", updatedWorkItem);
          dispatch(updateWorkItemStatus(updatedWorkItem));
          setSelectedWorkItem(updatedWorkItem);
          onUpdate(updatedWorkItem);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBackClick = () => {
    onClose();
  };

  const handlePictureClick = (photoUrl: string) => {
    setSelectedPhoto(photoUrl);
    setIsImageModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsImageModalOpen(false);
  };

  const handlePaymentSuccess = async () => {
    if (!selectedWorkItem) return;

    const updatedItem = await approveQuote(selectedWorkItem.id, selectedQuote.id, selectedQuote.contractor.id);
    if (updatedItem) {
      dispatch(updateWorkItemStatus(updatedItem));
      setSelectedWorkItem(updatedItem); // Update local state
      setClientSecret("");
      setPaymentAmount(0);
    }
  };

  const handleSelectQuote = (quote: Quote) => {
    setPendingQuote(quote);
    setIsConfirmModalOpen(true);
  };

  const handleRejectQuote = (quote: Quote) => {
    setPendingQuote(quote);
    setIsRejectConfirmModalOpen(true);
  };

  const handleConfirmQuoteSelection = async () => {
    if (!pendingQuote) return;
    setIsLoading(true);
    setIsConfirmModalOpen(false);
    try {
      if (!directPayment) {
        setSelectedQuote(pendingQuote);
        const updatedItem = await approveQuote(selectedWorkItem.id, pendingQuote.id, pendingQuote.contractor.id);
        if (updatedItem) {
          // Update local state to rerender
          setSelectedWorkItem(updatedItem);
          handleActionClick(updatedItem.status);
        }
        return;
      }
      const quoteAmount = getQuoteAmount(pendingQuote);
      if (isNaN(quoteAmount)) {
        console.error('Invalid quote amount:', pendingQuote.quote);
        return;
      }

      const amount = Math.round(quoteAmount * 100);
      setSelectedQuote(pendingQuote);
      const clientSecret = await createPaymentIntent(amount);
      setClientSecret(clientSecret);
      setPaymentAmount(amount);
    } catch (error) {
      console.error('Error creating payment intent:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmRejectQuote = async () => {
    if (!pendingQuote) return;

    setIsLoading(true);
    try {
      // Call the rejectQuote function and get the updated quote
      const updatedQuote = await rejectQuote(
        selectedWorkItem.id,
        pendingQuote.id
      );

      console.log('Rejected quote:', updatedQuote);

      // Update the local state to reflect the rejection
      const updatedQuotes = selectedWorkItem.quotes.map((quote) =>
        quote.id === updatedQuote.id ? { ...quote, rejected: true } : quote
      );

      setSelectedWorkItem({ ...selectedWorkItem, quotes: updatedQuotes });
      setPendingQuote(null);
    } catch (error) {
      console.error('Error rejecting quote:', error);
    } finally {
      setIsLoading(false);
      setIsRejectConfirmModalOpen(false);
    }
  };

  console.log("WorkItem: ", selectedWorkItem)
  console.log("Quotes: ", selectedWorkItem.quotes)

  return (
    <div className="fixed top-0 right-0 w-full h-full bg-white shadow-lg p-4 overflow-auto">
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <MoonLoader color={"#7E22CE"} size={50} />
        </div>
      )}
      <button
        className="bg-gray-500 text-white px-4 py-2 mb-4 rounded"
        onClick={handleBackClick}
      >
        Back
      </button>
      <h2 className="text-2xl mb-4">{selectedWorkItem.property.address}</h2>
      <p><span className="font-bold">Report type:</span> {WorkTypes[selectedWorkItem.workItemType] || selectedWorkItem.workItemType}</p>
      <p><span className="font-bold">Reported date:</span> {new Date(selectedWorkItem.createdAt).toLocaleDateString()}</p>
      <div className="mt-4 p-4 bg-gray-100 border border-gray-200">
        <span className="font-bold">Report summary:</span>
        <p>{selectedWorkItem.description}</p>
        {selectedWorkItem.photos && selectedWorkItem.photos.length > 0 && (
          <div className="mt-4 mx-auto max-w-[500px] w-full">
            {/* TODO define onVideoClick */}
            <Carousel media={selectedWorkItem.photos} onImageClick={handlePictureClick} onVideoClick={null} />
          </div>
        )}
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              { /* Only show the table if the Status is not INITIAL_REPORT or REQUESTING_QUOTES */}
              {selectedWorkItem.status !== WorkItemStatus.INITIAL_REPORT &&
                selectedWorkItem.status !== WorkItemStatus.REQUESTING_QUOTES &&
                (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Contractor
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          <div className="inline-flex items-center">
                            Estimated Quote (excl. VAT)
                            <div
                              className="relative flex items-center group ml-2"
                              onMouseEnter={() => setTooltipVisible(true)}
                              onMouseLeave={() => setTooltipVisible(false)}
                            >
                              <span className="cursor-pointer text-gray-500 hover:text-gray-700">?</span>

                              {/* Tooltip positioned absolutely within the th element */}
                              {isTooltipVisible && (
                                <div
                                  className="absolute z-50 p-1 text-xs text-white bg-black rounded shadow-lg"
                                  style={{ top: '20px', left: '10px', width: '150px' }} // Adjust width as needed
                                >
                                  Including applicable initial call-out, materials, parking, ULEZ/congestion, and vendor service fees
                                </div>
                              )}
                            </div>
                          </div>
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Note
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Lead Time Details
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Insured
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Certified
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {selectedWorkItem.quotes.map((quote) => (
                        <tr key={quote.contractor.name} className={quote.selected && 'bg-gray-50'}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            <div className="flex flex-col">
                              <span>{quote.contractor.name}</span>
                              <span>{quote.contractor.phone}</span>
                              <span>{quote.contractor.email}</span>
                            </div>
                          </td>
                          <td className="whitespace-normal max-w-[10vw] break-words px-3 py-4 text-sm text-gray-500">{formatQuote(quote, false)}</td>
                          <td className="whitespace-normal max-w-[30vw] break-words px-3 py-4 text-sm text-gray-500">{quote.note}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{quote.leadTimeDetails}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {quote.insured ? '✓' : '✗'}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {quote.certified ? '✓' : '✗'}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            {(selectedQuote && quote.id === selectedQuote.id || quote.selected) && (
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                stroke="green" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                              </svg>
                            )}
                            {quote.rejected ? (
                              <div className="flex items-center">
                                <span className="text-gray-500">(Quote Rejected)</span>
                              </div>
                            ) : (
                              selectedWorkItem.status === WorkItemStatus.QUOTE_SENT_PROPERTY_MANAGER &&
                              (selectedQuote == null || quote.id !== selectedQuote.id) && (
                                <div className="flex">
                                  <button
                                    onClick={() => handleSelectQuote(quote)}
                                    className="px-4 py-2 bg-green-500 text-white rounded-l-md">
                                    Approve
                                  </button>
                                  <button
                                    onClick={() => handleRejectQuote(quote)}
                                    className="px-4 py-2 bg-red-500 text-white rounded-r-md">
                                    Reject
                                  </button>
                                </div>
                              )
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
            </div>
          </div>
        </div>
      </div>
      {selectedWorkItem.repairSummary && (
        <div className="mt-4 p-4 bg-gray-100 border border-gray-200">
          <span className="font-bold">Work report:</span>
          <p>{selectedWorkItem.repairSummary.description}</p>
          {selectedWorkItem.repairSummary.photos && selectedWorkItem.repairSummary.photos.length > 0 && (
            <div className="mt-4">
              {/* TODO define onVideoClick */}
              <Carousel media={selectedWorkItem.repairSummary.photos} onImageClick={handlePictureClick} onVideoClick={null}/>
            </div>
          )}
        </div>
      )}
      {footerMessages[selectedWorkItem.status] && (
        <div className="mt-4 p-4 font-normal bg-green-100 shadow border-t border-gray-200">
          <p>{footerMessages[selectedWorkItem.status]}</p>
        </div>
      )}
      <div className="mt-4">
        {statusActionsPropertyManager[selectedWorkItem.status] && statusActionsPropertyManager[selectedWorkItem.status].map(action => (
          <button
            key={action.label}
            className="bg-blue-500 text-white px-4 py-2 mb-2 rounded"
            onClick={() => handleActionClick(action.status)}
          >
            {action.label}
          </button>
        ))}
        {clientSecret && (
          <div className="mt-4">
            <Elements stripe={stripePromise}>
              <PaymentForm clientSecret={clientSecret} amount={paymentAmount} onPaymentSuccess={handlePaymentSuccess} contractorName={selectedQuote.contractor.name} />
            </Elements>
          </div>
        )}
      </div>
      {isImageModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          onClick={handleCloseModal}  // Close modal on any click
        >
          <div className="bg-white p-4 rounded shadow-lg">
            <img
              src={`${selectedPhoto}`}
              alt="Report"
            />
          </div>
        </div>
      )}

      {/* Render the confirmation modal */}
      {isConfirmModalOpen && (
        <Modal>
          <div className="p-2">
            <p className="mb-4">Please confirm your quote selection: {pendingQuote?.contractor.name}</p>
            <div className="flex justify-end">
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded mr-2"
                onClick={() => setIsConfirmModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleConfirmQuoteSelection}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
      {/* Render the reject confirmation modal */}
      {isRejectConfirmModalOpen && (
        <Modal>
          <div className="p-2">
            <p className="mb-4">Are you sure you want to reject the quote from: {pendingQuote?.contractor.name}?</p>
            <div className="flex justify-end">
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded mr-2"
                onClick={() => setIsRejectConfirmModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleConfirmRejectQuote}
              >
                Reject
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default WorkItemDetail;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { submitContractorQuote, uploadRepairSummary, fetchContractorWorkItemPageInfo } from '../backend';
import Carousel from '../components/Carousel';
import { uploadFile } from '../utlis';

const ContractorWorkItemPage = () => {
  const { workItemId, contractorId } = useParams();
  const [workItem, setWorkItem] = useState(null);
  const [contractor, setContractor] = useState(null);
  const [currency, setCurrency] = useState('');
  const [error, setError] = useState(null);
  const [isRange, setIsRange] = useState(false);
  const [showQuoteForm, setShowQuoteForm] = useState(false);
  const [showReportSummaryForm, setShowReportSummaryForm] = useState(false);
  const [infoboxMessage, setInfoboxMessage] = useState(null);
  const [repairSummary, setRepairSummary] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  // Quote form state
  const [quoteData, setQuoteData] = useState({
    quote: '',
    note: '',
    leadTimeDetails: '',
    callOutFee: '',
    maxRange: '',
    currency: '',
  });

  // Repair Summary form state
  const [repairSummaryData, setRepairSummaryData] = useState({
    description: '',
    photos: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchContractorWorkItemPageInfo(workItemId, contractorId);
        const { workItem, contractor, currency, showQuoteForm, showReportSummaryForm, infoboxMessage, repairSummary } = response;

        setWorkItem(workItem);
        setContractor(contractor);
        setCurrency(currency);
        setShowQuoteForm(showQuoteForm);
        setShowReportSummaryForm(showReportSummaryForm);
        setInfoboxMessage(infoboxMessage);
        setRepairSummary(repairSummary);
        setQuoteData((prevState) => ({
          ...prevState,
          currency,
        }));
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Unauthorized access or error fetching data.');
      }
    };

    fetchData();
  }, [workItemId, contractorId]);

  const handleQuoteSubmit = async (e) => {
    e.preventDefault();

    // If range is selected, ensure both quote and maxRange are provided
    if (isRange && (!quoteData.quote || !quoteData.maxRange)) {
      alert('Please enter both lower and upper range.');
      return;
    }

    try {
      // Submit the quote
      const response = await submitContractorQuote(workItemId, contractorId, quoteData);
      console.log('Quote submitted:', response);

      // Re-fetch and update the entire page state
      const updatedResponse = await fetchContractorWorkItemPageInfo(workItemId, contractorId);
      const { workItem, contractor, currency, showQuoteForm, showReportSummaryForm, infoboxMessage, repairSummary } = updatedResponse;

      setWorkItem(workItem);
      setContractor(contractor);
      setCurrency(currency);
      setShowQuoteForm(showQuoteForm);
      setShowReportSummaryForm(showReportSummaryForm);
      setInfoboxMessage(infoboxMessage);
      setRepairSummary(repairSummary);
      setQuoteData((prevState) => ({
        ...prevState,
        currency,
      }));

      alert('Quote submitted successfully and work item updated!');
    } catch (err) {
      console.error('Error submitting quote:', err);
      alert('Error submitting quote.');
    }
  };

  const handleFileSelect = (e) => {
    setSelectedFiles([...e.target.files]);
  };

  const handleRepairSummarySubmit = async (e) => {
    e.preventDefault();

    try {
      setUploading(true);

      // Upload selected files to S3
      const uploadedPhotoUrls = await Promise.all(
        selectedFiles.map(async (file) => {
          const url = await uploadFile(file);
          return url;
        })
      );

      // Set the uploaded URLs in repairSummaryData
      const updatedRepairSummaryData = {
        ...repairSummaryData,
        photos: uploadedPhotoUrls,
      };

      // Submit the repair summary after file upload
      const response = await uploadRepairSummary(workItemId, contractorId, updatedRepairSummaryData);
      console.log('Repair summary uploaded:', response);
      alert('Repair summary uploaded successfully!');

      // Re-fetch the updated WorkItem to update the UI
      const updatedResponse = await fetchContractorWorkItemPageInfo(workItemId, contractorId);
      const { workItem, contractor, currency, showQuoteForm, showReportSummaryForm, infoboxMessage, repairSummary } = updatedResponse;

      setWorkItem(workItem);
      setContractor(contractor);
      setCurrency(currency);
      setShowQuoteForm(showQuoteForm);
      setShowReportSummaryForm(showReportSummaryForm);
      setInfoboxMessage(infoboxMessage);
      setRepairSummary(repairSummary);

      // Clear selected files and stop uploading
      setSelectedFiles([]);
      setUploading(false);
    } catch (err) {
      console.error('Error uploading repair summary:', err);
      alert('Error uploading repair summary.');
      setUploading(false);
    }
  };

  if (error) {
    return <div className="text-red-500 font-bold">{error}</div>;
  }

  if (!workItem || !contractor || !currency) {
    return <div className="text-center mt-4">Loading...</div>;
  }

  console.log("workItem: ", workItem);
  console.log("contractor: ", contractor);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <img src="/favicon.png" alt="Coflow Logo" className="w-8 h-8 mb-2" />

      <h1 className="text-2xl font-bold mb-4">Coflow Contractor Dashboard</h1>

      {/* Infobox section - show only if infoboxMessage exists */}
      {infoboxMessage && (
        <div className="mb-4 p-4 bg-yellow-100 border border-yellow-400 text-yellow-800 rounded-md">
          {infoboxMessage}
        </div>
      )}

      {/* Contractor Details Section */}
      <div className="bg-blue-100 p-3 mb-3 rounded-lg shadow-md">
        <h2 className="text-l font-semibold mb-2">Your Details</h2>
        <p> {contractor.name || 'N/A'}</p>
        <p> {contractor.email || 'N/A'}</p>
        <p> {contractor.phone || 'N/A'}</p>
      </div>

      {/* Work Item Details Section */}
      <div className="bg-gray-100 p-3 mb-3 rounded-lg shadow-md">
        <h2 className="text-l font-bold mb-4">Work Item for {workItem.property.address}, {workItem.property.postCode}</h2>
        <p><strong>Description:</strong> {workItem.description}</p>
        <p><strong>Status:</strong> {workItem.status}</p>

        {workItem.photos && workItem.photos.length > 0 && (
          <div className="mt-4 mx-auto max-w-[500px] w-full">
            <Carousel media={workItem.photos} onImageClick={() => { }} onVideoClick={undefined} />
          </div>
        )}
      </div>

      {/* Submit a Quote Section - Only show when the status is REQUESTING_QUOTES */}
      {showQuoteForm && (
        <>
          <h2 className="text-xl font-semibold mb-4">Submit your Quote for this Work</h2>
          <form onSubmit={handleQuoteSubmit} className="mb-8">
            {/* Checkbox to enable maxRange */}
            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                id="isRange"
                checked={isRange}
                onChange={(e) => setIsRange(e.target.checked)}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
              <label htmlFor="isRange" className="ml-2 block text-sm font-medium text-gray-700">
                Enter ranged estimate
              </label>
            </div>

            {/* Quote amount or lower range */}
            {isRange ? (
              <div className="mb-4 flex space-x-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">From:</label>
                  <div className="flex items-center">
                    <span className="text-gray-700 font-medium mr-2">{currency}</span>
                    <input
                      type="number"
                      value={quoteData.quote}
                      onChange={(e) => setQuoteData({ ...quoteData, quote: e.target.value })}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">To:</label>
                  <div className="flex items-center">
                    <span className="text-gray-700 font-medium mr-2">{currency}</span>
                    <input
                      type="number"
                      value={quoteData.maxRange}
                      onChange={(e) => setQuoteData({ ...quoteData, maxRange: e.target.value })}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="mb-4 flex items-center">
                <span className="text-gray-700 font-medium mr-2">{currency}</span>
                <input
                  type="number"
                  value={quoteData.quote}
                  onChange={(e) => setQuoteData({ ...quoteData, quote: e.target.value })}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            )}

            {/* Call Out Fee */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Call Out Fee <span className="text-gray-500">(Optional):</span>
              </label>
              <div className="flex items-center">
                <span className="text-gray-700 font-medium mr-2">{currency}</span>
                <input
                  type="number"
                  value={quoteData.callOutFee}
                  onChange={(e) => setQuoteData({ ...quoteData, callOutFee: e.target.value })}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            {/* Note */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Comment:</label>
              <textarea
                value={quoteData.note}
                onChange={(e) => setQuoteData({ ...quoteData, note: e.target.value })}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Lead Time Details */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Lead Time Details:</label>
              <input
                type="text"
                value={quoteData.leadTimeDetails}
                onChange={(e) => setQuoteData({ ...quoteData, leadTimeDetails: e.target.value })}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-150"
            >
              Submit Quote
            </button>
          </form>
        </>
      )}

      {/* Conditionally show the "Upload Repair Summary" section if contractor is approved */}
      {showReportSummaryForm && (
        <>
          <h2 className="text-xl font-semibold mb-4">Upload Repair Summary</h2>
          <form onSubmit={handleRepairSummarySubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Description:</label>
              <textarea
                value={repairSummaryData.description}
                onChange={(e) => setRepairSummaryData({ ...repairSummaryData, description: e.target.value })}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Image Upload Input */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Upload Photos:</label>
              <input
                type="file"
                multiple
                onChange={handleFileSelect}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <button
              type="submit"
              disabled={uploading}
              className={`w-full ${uploading ? 'bg-gray-500' : 'bg-green-500'} text-white py-2 px-4 rounded-md hover:bg-green-600 transition duration-150`}
            >
              {uploading ? 'Uploading...' : 'Upload Repair Summary'}
            </button>
          </form>
        </>
      )}

      {/* Display Repair Summary (if available) */}
      {repairSummary && (
        <div className="mt-8 bg-gray-100 p-4 rounded-md shadow-md">
          <h2 className="text-xl font-semibold mb-4">Repair Summary</h2>
          <p><strong>Description:</strong> {repairSummary.description}</p>
          {repairSummary.photos.length > 0 && (
            <div className="mt-4">
              <h3 className="text-md font-semibold">Uploaded Photos:</h3>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {repairSummary.photos.map((photoUrl, index) => (
                  <img key={index} src={photoUrl} alt={`Repair Photo ${index + 1}`} className="w-full h-auto rounded-md shadow-sm" />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContractorWorkItemPage;

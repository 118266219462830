import React from 'react';
import { Property, Tenant } from '../../types/types';

interface PMTenantFormProps {
  properties: Property[];
  newTenant: Omit<Tenant, 'id' | 'property'> & { password: string };
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  handleSubmit: () => void;
  handleClose: () => void;
  errorMessage: string;
  isEditMode: boolean;
}

const PMTenantForm: React.FC<PMTenantFormProps> = ({
  properties,
  newTenant,
  handleInputChange,
  handleSubmit,
  handleClose,
  errorMessage,
  isEditMode
}) => {

  return (
    <div className="bg-white w-96 p-2 rounded">
      <h3 className="text-2xl mb-2">{isEditMode ? 'Edit Tenant' : 'Add Tenant'}</h3>

      {/* Name */}
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          name="name"
          placeholder="Primary tenant's name"
          value={newTenant.name}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>

      {/* Email */}
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          name="email"
          placeholder="Primary tenant's email"
          value={newTenant.email}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>

      {/* Phone */}
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700">Phone</label>
        <input
          type="text"
          name="phone"
          placeholder="Primary tenant's phone number"
          value={newTenant.phone}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>

      {/* Password */}
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700">Password</label>
        <input
          type="password"
          name="password"
          value={newTenant.password}
          onChange={handleInputChange}
          required={!isEditMode}
          autoComplete="new-password"
          placeholder={isEditMode ? 'Enter to change tenant\'s password' : 'Password'}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>

      {/* Preferred Communication */}
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700">Preferred Communication</label>
        <select
          name="preferredCommunication"
          value={newTenant.preferredCommunication}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="EMAIL">Email</option>
          <option value="WHATSAPP">WhatsApp</option>
          <option value="ANY">Any</option>
        </select>
      </div>

      {/* Property selection in creation mode */}
      {!isEditMode && (
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Property</label>
          <select
            name="propertyId"
            value={newTenant.propertyId}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">Select Property</option>
            {properties.map((property) => (
              <option key={property.id} value={property.id}>
                {property.address}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Alternative Name */}
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700">Alternative Contact Person</label>
        <input
          type="text"
          name="secondaryName"
          placeholder="Alternative contact's Name"
          value={newTenant.secondaryName || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>

      {/* Secondary Email */}
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700">Alternative Email</label>
        <input
          type="email"
          name="secondaryEmail"
          placeholder="Alternative email"
          value={newTenant.secondaryEmail || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>

      {/* Secondary Phone */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Alternative Phone</label>
        <input
          type="text"
          name="secondaryPhone"
          placeholder="Anternative phone"
          value={newTenant.secondaryPhone || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>

      {/* Error Message */}
      {errorMessage && (
        <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
          {errorMessage}
        </div>
      )}

      {/* Action Buttons */}
      <div className="flex justify-end">
        <button
          className="bg-gray-500 text-white px-4 py-2 mr-2 rounded hover:bg-gray-600"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={handleSubmit}
        >
          {isEditMode ? 'Save Changes' : 'Add Tenant'}
        </button>
      </div>
    </div>
  );
};

export default PMTenantForm;

import React, { useState } from 'react';
import { statusColors, WorkItemStatus, statusMappingPropertyManager, subHeaderPropertyManager } from '../../types/globals';
import { WorkItem } from '../../types/types';
import { uploadFile } from '../../utlis';
import { createWorkItem, markRemovedWorkItem } from '../../backend';
import { useAppDispatch } from '../../hooks';
import { addWorkItem, updateWorkItemStatus } from '../../actions/workItems';
import { MoonLoader } from 'react-spinners';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '../Modal';
import PMWorkItemForm from '../creationForm/PMWorkItemForm';

const PMWorkItemsView = ({ workItems, properties, onWorkItemClick, onAddWorkItem, onUpdateWorkItem }) => {

  const filteredWorkItems = workItems.filter(item => item.status !== WorkItemStatus.REMOVED);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newWorkItem, setNewWorkItem] = useState<WorkItem>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedWorkItemId, setSelectedWorkItemId] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewWorkItem({ ...newWorkItem, [name]: value });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const uploadedFiles = await Promise.all(Array.from(files).map(uploadFile));
      setNewWorkItem({ ...newWorkItem, photos: uploadedFiles });
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      console.log("newWorkItem: ", newWorkItem);
      const response: WorkItem = await createWorkItem(newWorkItem);
      response.property = properties.find(item => item.id === response.propertyId);
      dispatch(addWorkItem(response));
      onAddWorkItem(response);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error creating work item:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (workItemId) => {
    setSelectedWorkItemId(workItemId);
    setIsConfirmModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!selectedWorkItemId) return;
    try {
      const result = await markRemovedWorkItem(selectedWorkItemId);
      console.log("Deleted workItem: ", result);

      // Dispatch the action to update the work item status to REMOVED
      dispatch(updateWorkItemStatus({ ...result.workItem, status: WorkItemStatus.REMOVED }));

      // Call the update handler passed from the parent component
      onUpdateWorkItem(result.workItem);

      setIsConfirmModalOpen(false);
      setSelectedWorkItemId(null); // Reset selected work item
    } catch (error) {
      console.error('Error deleting work item:', error);
    }
  };

  const cancelDelete = () => {
    setIsConfirmModalOpen(false);
    setSelectedWorkItemId(null);
  };

  const showAIForStatuses = [
    WorkItemStatus.QUOTE_AGREED,
    WorkItemStatus.SCHEDULING_WORK,
    WorkItemStatus.REQUESTING_QUOTES,
    WorkItemStatus.QUOTE_SENT_TO_LANDLORD
  ];

  return (
    <div className="h-full">
      <div className="flex justify-between items-center mb-4 top-0 z-10">
        <h2 className="text-2xl">Maintenance Task Panel</h2>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
          onClick={() => setIsModalOpen(true)}
        >
          <span className="mr-1">+</span> Add Work Item
        </button>
      </div>
      <div className="overflow-y-auto h-[calc(100vh-150px)]">
        <div className="grid grid-cols-1 gap-4">
          {filteredWorkItems.map((item: WorkItem) => (
            <div
              key={item.id}
              className={`p-4 border rounded shadow group hover:cursor-pointer ${statusColors[item.status]}`}
              onClick={() => onWorkItemClick(item)}
            >
              <div className="flex justify-between">
                <div className="flex items-center">
                  <span className="font-bold">{item.property.address}</span>
                  {
                    // only WorkItem of Status INITIAL_REPORT, QUOTES_AVAILABLE, REQUESTING_QUOTES, QUOTE_SENT_PROPERTY_MANAGER and QUOTE_SENT_TO_LANDLORD can be removed
                    (item.status === WorkItemStatus.INITIAL_REPORT ||
                      item.status === WorkItemStatus.QUOTES_AVAILABLE ||
                      item.status === WorkItemStatus.REQUESTING_QUOTES ||
                      item.status === WorkItemStatus.QUOTE_SENT_PROPERTY_MANAGER ||
                      item.status === WorkItemStatus.QUOTE_SENT_TO_LANDLORD) && (
                      <button
                        className="text-red-500 ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(item.id);
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    )}
                </div>
                {showAIForStatuses.includes(item.status) && <span className="text-green-500 font-extrabold">AI</span>}
              </div>
              <span>{statusMappingPropertyManager[item.status]}</span>
              {subHeaderPropertyManager[item.status] && (
                <span className="ml-2 px-2 py-1 rounded bg-gray-200 text-sm">
                  {subHeaderPropertyManager[item.status]}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <Modal>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <MoonLoader color={"#7E22CE"} size={50} />
            </div>
          ) : (
            <PMWorkItemForm
              properties={properties}
              newWorkItem={newWorkItem}
              handleInputChange={handleInputChange}
              handleFileChange={handleFileChange}
              handleSubmit={handleSubmit}
              handleClose={() => setIsModalOpen(false)}
            />
          )}
        </Modal>
      )}

      {isConfirmModalOpen && (
        <Modal>
          <div className="p-6">
            <h2 className="text-xl mb-4">Do you want to delete the WorkItem?</h2>
            <p>All existing outreach to relevant contractors and quotes will be terminated.</p>
            <div className="mt-6 flex justify-end">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={confirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PMWorkItemsView;

import React, { useState } from "react";
import { Navigate } from "react-router";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import MoonLoader from "react-spinners/MoonLoader";
import { AuthState } from '../types/types';
import { roleToPathMap, UserRole } from '../types/globals';

interface LoginDispatchProps {
  login: (email: string, password: string, role: UserRole) => Promise<void>;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
}

function Login(props: LoginDispatchProps) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    role: UserRole.PropertyManager,
  });

  const { email, password, role } = formData;

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e: React.KeyboardEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userLogin = async () => {
      setLoading(true);
      await props.login(email, password, role);
      setLoading(false);
    };
    userLogin();
  };

  if (props.isAuthenticated) {
    return <Navigate to={roleToPathMap[role] + window.location.search} />;
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(249, 250, 251)",
      }}
    >
      <div
        className="flex h-16 shrink-0 items-center px-6"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          cursor: "pointer",
        }}
        onClick={() => (window.location.href = "https://www.joincoflow.com/")}
      >
        <img className="h-8 w-auto" src="/favicon.png" alt="Coflow" />
        <span className="self-center text-xl font-semibold whitespace-nowrap pl-2">
          Coflow
        </span>
      </div>
      {loading ? (
        <div style={{ width: "fit-content", height: "fit-content" }}>
          <MoonLoader color={"#7E22CE"} loading={true} size={50} />
        </div>
      ) : (
        <>
          <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <h2 className="my-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to Coflow{" "}
                <img
                  src="https://em-content.zobj.net/source/apple/354/sparkles_2728.png"
                  alt="robot"
                  style={{
                    display: "inline-block",
                    width: "1.7rem",
                    height: "1.7rem",
                    verticalAlign: "top",
                    lineHeight: "1.7rem",
                    marginTop: "4px",
                  }}
                />
              </h2>
              {props.error && (
                <div
                  className="text-center text-red-500"
                  style={{
                    backgroundColor: "#ffe8e7",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    fontWeight: "bold",
                  }}
                >
                  {props.error}
                </div>
              )}
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-6 shadow sm:rounded-lg sm:px-10">
                <form className="space-y-6" onSubmit={(e) => onSubmit(e)}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={email}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={password}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Role
                    </label>
                    <div className="mt-2">
                      <select
                        id="role"
                        name="role"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={role}
                        onChange={(e) => onChange(e)}
                      >
                        <option value={UserRole.PropertyManager}>Property Manager</option>
                        <option value={UserRole.Tenant}>Tenant</option>
                        <option value={UserRole.Landlord}>Landlord</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
                <p className="mt-2 text-center text-sm text-gray-600">
                  Register as a Property Manager?&nbsp;
                  <a
                    href="/signup"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Sign Up
                  </a>
                </p>
                <p className="mt-2 text-center text-sm text-gray-600">
                  Forgot password?&nbsp;
                  <a
                    href="mailto:alexander@coflow.com"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Send us a mail
                  </a>
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state: AuthState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  error: state.auth.error,
});

export default connect(mapStateToProps, { login })(Login);

import {useEffect, useState} from 'react'
import { connect } from "react-redux";
import {useNavigate} from "react-router";
import {Profile} from '../types/types'
import {useAppDispatch, useAppSelector} from '../hooks'
import { roleToPathMap, UserRole } from '../types/globals'
import PropertyManagement from '../pages/PropertyManagement'
import LandlordPage from '../pages/LandlordPage'
import TenantPage from '../pages/TenantPage'
import {loadUser} from "../backend";
import {loadProfile} from "../actions/profile";

const roleToComponentMap: Record<UserRole, React.FC> = {
  [UserRole.Landlord]: LandlordPage,
  [UserRole.PropertyManager]: PropertyManagement,
  [UserRole.Tenant]: TenantPage,
};

const PrivateRoute = ({
  isAuthenticated,
  children
}) => {
  const navigate = useNavigate()
  const user: Profile  = useAppSelector((store) => store.profile);
  const dispatch = useAppDispatch();
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    async function fetchUser() {
      const user = await loadUser();
      if (user) {
        dispatch(loadProfile(user));
        setLoadingUser(false)
      }
      else {
        navigate("/login")
      }
      // Set loading to false after user data is fetched
    }
    if (isAuthenticated) {
      if (children.type !== roleToComponentMap[user.role]) {
        navigate(roleToPathMap[user.role]);
      }
      setLoadingUser(false)
    }
    else {
      fetchUser()
    }
  }, [dispatch, isAuthenticated, navigate, loadingUser]);

  // If authenticated, render the children components
  return !loadingUser && user ? children : null;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(PrivateRoute);

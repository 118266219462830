import React, { useState } from 'react';
import { Landlord } from '../../types/types';
import { createLandlord, updateLandlord } from '../../backend';
import { MoonLoader } from 'react-spinners';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '../Modal';
import PMLandlordForm from '../creationForm/PMLandlordForm';

interface NewLandlord extends Omit<Landlord, 'id'> {
  password: string;
}

const PMLandlordsView = ({ landlords, onAddLandlord, onUpdateLandlord, propertyManagerId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingLandlordId, setEditingLandlordId] = useState<string | null>(null);

  const [thisLandlord, setThisLandlord] = useState<NewLandlord>({
    name: '',
    email: '',
    phone: '',
    password: '',
  });

  const [formErrorMessage, setFormErrorMessage] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setThisLandlord({ ...thisLandlord, [name]: value });
  };

  const handleClose = async () => {
    // reset current Landlord value -> Form will be emptied out after closure
    setThisLandlord({
      name: '',
      email: '',
      phone: '',
      password: ''
    })
    setFormErrorMessage('')
    setIsModalOpen(false)
  }

  const handleSubmit = async () => {
    setIsLoading(true);

    // Validate fields before proceeding
    if (!thisLandlord.name || !thisLandlord.email || !thisLandlord.phone || (!isEditMode && !thisLandlord.password)) {
        setFormErrorMessage('Please fill out all required fields.');
        setIsLoading(false);
        return;
    }

    try {
      let response;
      if (isEditMode && editingLandlordId !== null) {
        // Update existing landlord
        response = await updateLandlord(editingLandlordId, thisLandlord);
      } else {
        // Create new landlord
        response = await createLandlord(thisLandlord, propertyManagerId);
      }

      if (response.error) {
        console.log('Error on handleSubmit: ', response.error);
        setFormErrorMessage(response.error);
        return;
      }

      if (isEditMode && editingLandlordId !== null) {
        onUpdateLandlord(response);
      } else {
        onAddLandlord(response);
      }

      setIsModalOpen(false);
      setEditingLandlordId(null);
      setIsEditMode(false);
    } catch (error) {
      console.error('Error:', error);
      setFormErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
};

  const handleEditClick = (landlord: Landlord) => {
    setThisLandlord({
      name: landlord.name,
      email: landlord.email,
      phone: landlord.phone,
      password: '',
    });
    setEditingLandlordId(landlord.id);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  return (
    <div className="h-full">
      <div className="flex justify-between items-center mb-4 sticky top-0 z-10">
        <h2 className="text-2xl">Landlords Panel</h2>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
          onClick={() => {
            setThisLandlord({
              name: '',
              email: '',
              phone: '',
              password: '',
            });
            setIsEditMode(false);
            setIsModalOpen(true);
          }}
        >
          <span className="mr-2">+</span> Add Landlord
        </button>
      </div>
      <div className="overflow-y-auto h-[calc(100vh-150px)]">
        <div className="grid grid-cols-1 gap-4">
          {landlords.map((landlord) => (
            <div
              key={landlord.id}
              className="p-4 border rounded shadow group hover:bg-gray-100 transition-colors duration-200"
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <span className="font-bold">{landlord.name}</span>
                  <button
                    className="text-blue-500 ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                    onClick={() => handleEditClick(landlord)}
                  >
                    <EditIcon />
                  </button>
                </div>
                <span className="text-gray-500">{landlord.email}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500">{landlord.phone}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <Modal>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <MoonLoader color="#7E22CE" size={50} />
            </div>
          ) : (
            <PMLandlordForm
              newLandlord={thisLandlord}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
              errorMessage={formErrorMessage}
              isEditMode={isEditMode}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default PMLandlordsView;

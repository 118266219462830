import { Config } from './types/globals'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { v4 as uuidv4 } from 'uuid';
import { Quote } from './types/types'

export const uploadFile = async (file: File) => {
  // Create S3 client
  const s3Client = new S3Client({
    credentials: {
      accessKeyId: Config.AWS_ACCESS_KEY_ID,
      secretAccessKey: Config.AWS_SECRET_ACCESS_KEY,
    },
    region: Config.REGION,
    logger: console
  });

  // Generate a UUID for the file name and keep the original file extension
  const uuidFileName = `${uuidv4()}${file.name.substring(file.name.lastIndexOf('.'))}`;

  // Set upload parameters
  const params = {
    Body: file,
    Bucket: Config.S3_BUCKET,
    Key: encodeURIComponent(uuidFileName),
  };

  console.log("Uploading file")

  try {
    // Upload file to S3
    const command = new PutObjectCommand(params);
    const response = await s3Client.send(command);
    console.log("File uploaded successfully", response);
    // Construct the URL for the uploaded file
    return `https://${Config.S3_BUCKET}.s3.${Config.REGION}.amazonaws.com/${encodeURIComponent(uuidFileName)}`
  } catch (error) {
    console.error("Error uploading file", error);
    return '';
  }
};

export function getEnv(name: string): string {
  if (typeof process.env[name] === 'undefined') {
    throw new Error(`Variable ${name} undefined.`)
  }

  return process.env[name]

}

export const formatQuote = (
  quote: Quote,
  includeTax: boolean,
  taxFactor?: number
) => {
  // Helper function to apply tax if needed
  const applyTax = (amount: number) => {
    return includeTax && taxFactor ? amount * (1 + taxFactor) : amount;
  };

  // Apply tax to quote amounts
  const quotedAmount = applyTax(quote.quote);
  const maxRangeAmount = quote.maxRange ? applyTax(quote.maxRange) : null;
  const callOutFeeAmount = quote.callOutFee ? applyTax(quote.callOutFee) : null;

  if (callOutFeeAmount) {
    if (maxRangeAmount) {
      return `${quote.currency} ${quotedAmount.toFixed(1)}~${quote.currency} ${maxRangeAmount.toFixed(1)} (+ ${quote.currency} ${callOutFeeAmount.toFixed(1)} initial call-out)`;
    }
    return `${quote.currency} ${quotedAmount.toFixed(1)} (+ ${quote.currency} ${callOutFeeAmount.toFixed(1)} initial call-out)`;
  }
  if (maxRangeAmount) {
    return `${quote.currency} ${quotedAmount.toFixed(1)}~${quote.currency} ${maxRangeAmount.toFixed(1)}`;
  }
  return `${quote.currency} ${quotedAmount.toFixed(1)}`;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed in JS
  return `${day}/${month}`;
};

export const getQuoteAmount = (quote: Quote) => {
  // how we calculate quote amount (maxRange if it is defined + callOut + VAT)
  if (quote.maxRange !== null) {
    return (quote.maxRange + (quote.callOutFee || 0)) * 1.2
  }
  return (quote.quote + (quote.callOutFee || 0)) * 1.2
}

import React, { useState } from "react";
import { uploadFile } from "../utlis";
import { WorkItem } from "../types/types";
import { WorkTypes } from "../types/globals";
import { MoonLoader } from "react-spinners";

const TenantWorkItemForm: React.FC<{
  workItem: Partial<WorkItem>,
  onFormChange: (item: Partial<WorkItem>, callback?: (updatedWorkItem: Partial<WorkItem>) => void) => void,
  onFormSubmit: (updatedWorkItem: Partial<WorkItem>) => void,
  onFormClose: () => void,
  propertyAddress: string,
  isLoading: boolean // external loading prop for other operations
}> = ({ workItem, onFormChange, onFormSubmit, onFormClose, propertyAddress, isLoading }) => {

  /**
   * TenantWorkItemForm Component
   *
   * This component handles the creation of a new work item report by the tenant.
   * Here's how it works:
   *
   * 1. **Form Rendering**:
   *    - Renders a form where tenants can describe the issue, select the type of issue,
   *      and upload any relevant photos to illustrate the problem.
   *
   * 2. **State for File Handling**:
   *    - Uses a local state variable `localFiles` to keep track of files that the user selects.
   *    - Files are stored here temporarily until the user decides to submit the form.
   *
   * 3. **Handling Input Changes**:
   *    - When the user types in the description or selects a work item type,
   *      the `handleChange` function updates the state in `ReportPage` via `onFormChange`.
   *    - `onFormChange` ensures the main page's state is always in sync with what the user is inputting.
   *
   * 4. **File Selection**:
   *    - The `handleFileChange` function updates `localFiles` whenever a user selects files.
   *    - This ensures that the selected files are ready for upload when the form is submitted.
   *
   * 5. **Submission Logic**:
   *    - On hitting submit, `handleSubmit` is called:
   *      - It first uploads all files in `localFiles` to get their URLs.
   *      - These URLs are then added to the `workItem` as `photos`.
   *      - The form's data, now including the file URLs, is sent back to `ReportPage` using `onFormChange`.
   *      - A callback `onFormSubmit` is also passed to ensure that once the state is updated, the form data is submitted.
   *    - This callback mechanism makes sure that submission only occurs after all data, including photos, is correctly set.
   *
   * 6. **Closing the Form**:
   *    - A cancel button is provided to close the form without saving changes.
   */

  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false); // Local state for handling upload

  // Handles changes in the form fields for description and work item type
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onFormChange({ ...workItem, [name]: value });
  };

  // Handles file selection and updates localFiles state
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setLocalFiles(Array.from(files));
    }
  };

  // Handles form submission: uploads files and updates state before triggering submission
  const handleSubmit = async () => {
    setIsUploading(true);
    try {
      const uploadedFiles = await Promise.all(localFiles.map(uploadFile));
      console.log("File uploaded: ", uploadedFiles);

      console.log("Modified workItem: ", { ...workItem, photos: uploadedFiles });
      onFormChange({ ...workItem, photos: uploadedFiles }, onFormSubmit);
    } catch (error) {
      console.error("Error uploading files: ", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="relative">
      {(isLoading || isUploading) && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
          <MoonLoader color="#7E22CE" size={50} />
        </div>
      )}
      <h2 className="text-xl mb-4">Report on Issue at {propertyAddress}</h2>
      <textarea
        name="description"
        value={workItem.description || ""}
        onChange={handleChange}
        placeholder="Describe the issue"
        className="w-full p-2 border border-gray-300 rounded mb-4"
        // Disable input when loading
        disabled={isUploading || isLoading}
      />
      <select
        name="workItemType"
        value={workItem.workItemType || ""}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded mb-4"
        disabled={isUploading || isLoading}
      >
        <option value="">What kind of issue is this?</option>
        {Object.keys(WorkTypes).map((key) => (
          <option key={key} value={key}>
            {WorkTypes[key as keyof WorkTypes]}
          </option>
        ))}
      </select>
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        className="mb-4"
        // Disable file input when loading
        disabled={isUploading || isLoading}
      />
      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
          // Disable button when loading
          disabled={isUploading || isLoading}
        >
          Submit
        </button>
        <button
          onClick={onFormClose}
          className="px-4 py-2 bg-gray-500 text-white rounded"
          // Disable button when loading
          disabled={isUploading || isLoading}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default TenantWorkItemForm;

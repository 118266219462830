import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  getWorkItems,
  getTenantsByPM,
  getAllPropertiesByPM,
  getLandlordsByPM,
  getPropertiesWithoutTenantByPM,
} from '../backend';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSelectedWorkItem } from '../actions/workItems';
import PMWorkItemsView from '../components/propertyManagement/PMWorkItemsView';
import PMPropertiesView from '../components/propertyManagement/PMPropertiesView';
import PMTenantsView from '../components/propertyManagement/PMTenantsView';
import PMLandlordsView from '../components/propertyManagement/PMLandlordsView';
import WorkItemDetail from '../pages/WorkItemDetail';
import { Property, WorkItem, Tenant, Landlord } from '../types/types';

// Material Icons
import BuildIcon from '@mui/icons-material/Build';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import Logout from "../components/Logout";

const PropertyManagement = () => {
  const [activeTab, setActiveTab] = useState('maintenance');
  const [workItems, setWorkItems] = useState<WorkItem[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const [propertiesWithoutTenant, setPropertiesWithoutTenant] = useState<Property[]>([]);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [landlords, setLandlords] = useState<Landlord[]>([]);
  const [selectedWorkItem, setSelectedWorkItemState] = useState<WorkItem | null>(null);
  const user = useAppSelector((store) => store.profile);
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedWorkItems = await getWorkItems('management', user.email);
        setWorkItems(fetchedWorkItems);

        const fetchedProperties = await getAllPropertiesByPM(user.id);
        setProperties(fetchedProperties);

        const fetchedPropertiesWithoutTenant = await getPropertiesWithoutTenantByPM(user.id);
        console.log("propertiesWithoutTenant ", fetchedPropertiesWithoutTenant)
        setPropertiesWithoutTenant(fetchedPropertiesWithoutTenant);

        const fetchedTenants = await getTenantsByPM(user.id);
        setTenants(fetchedTenants);

        const fetchedLandlords = await getLandlordsByPM(user.id);
        setLandlords(fetchedLandlords);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [user.email, user.id]);

  useEffect(() => {
    console.log('Work items updated:', workItems);
  }, [workItems]);


  const handleAddWorkItem = (newWorkItem) => {
    setWorkItems(prevWorkItems => [...prevWorkItems, newWorkItem]);
  };

  const handleAddProperty = (newProperty: Property) => {
    setProperties(prevProperties => [...prevProperties, newProperty]);
    setPropertiesWithoutTenant(prevProperties => [...prevProperties, newProperty]);
  };

  const handleUpdateProperty = (updatedProperty: Property) => {
    setProperties(prevProperties => prevProperties.map(property =>
      property.id === updatedProperty.id ? updatedProperty : property
    ));

    // Update tenants with updated property information
    setTenants(prevTenants => prevTenants.map(tenant =>
      tenant.propertyId === updatedProperty.id ? { ...tenant, property: updatedProperty } : tenant
    ));

    // Update work items with updated property information
    setWorkItems(prevWorkItems => prevWorkItems.map(workItem =>
      workItem.propertyId === updatedProperty.id ? { ...workItem, property: updatedProperty } : workItem
    ));
  };

  const handleAddTenant = (newTenant: Tenant, propertyId: string) => {
    setTenants(prevTenants => [...prevTenants, newTenant]);

    setPropertiesWithoutTenant(prevProperties =>
      prevProperties.filter(property => property.id !== newTenant.propertyId)
    );

    setProperties(prevProperties =>
      prevProperties.map(property => {
        return property.id === propertyId ? { ...property, tenant: newTenant } : property;
      })
    );

    console.log("Updated Properties: ", properties);
  };


  const handleUpdateTenant = (updatedTenant: Tenant) => {
    setTenants(prevTenants => prevTenants.map(tenant =>
      tenant.id === updatedTenant.id ? updatedTenant : tenant
    ));

    // Update property with tenant information
    setProperties(prevProperties => prevProperties.map(property =>
      property.id === updatedTenant.propertyId ? { ...property, tenant: updatedTenant } : property
    ));
  };

  const handleWorkItemUpdate = (updatedWorkItem: WorkItem) => {
    console.log("updatedWorkItem @ handleWorkItemUpdate: ", updatedWorkItem)
    setWorkItems(prevWorkItems => prevWorkItems.map(item =>
      item.id === updatedWorkItem.id ? { ...updatedWorkItem } : item
    ));
    console.log("current workItems: ", workItems)
  };


  const handleWorkItemClick = (workItem: WorkItem) => {
    setSelectedWorkItemState(workItem);
    dispatch(setSelectedWorkItem(workItem));
  };

  const handleModalClose = () => {
    setSelectedWorkItemState(null);
  };

  const handleAddLandlord = (newLandlord) => {
    setLandlords(prevLandlords => [...prevLandlords, newLandlord]);
  };

  const handleUpdateLandlord = (updatedLandlord) => {
    setLandlords(prevLandlords => prevLandlords.map(landlord =>
      landlord.id === updatedLandlord.id ? updatedLandlord : landlord
    ));
  };

  return (
    <div className="flex h-screen">
      {/* Icons disappear on small screen for better looks */}
      <div className="flex flex-col w-1/4 bg-gray-100 p-4">
        <Link to="/propertyManager">
          <div className="flex items-center mb-4">
            <img src="/favicon.png" className="hidden lg:block h-6 mr-3 sm:h-9" alt="Coflow Logo" />
            <span className="block lg:hidden self-center text-xl font-semibold whitespace-nowrap">Coflow</span>
            <span className="hidden lg:block self-center text-xl font-semibold whitespace-nowrap">Coflow Dashboard</span>
          </div>
        </Link>
        <button
          className={`p-2 mb-2 flex items-center ${activeTab === 'maintenance' ? 'bg-blue-500 text-white' : 'bg-white'}`}
          onClick={() => setActiveTab('maintenance')}
        >
          <BuildIcon />
          <span className="hidden lg:block ml-2">Maintenance</span>
        </button>
        <button
          className={`p-2 mb-2 flex items-center ${activeTab === 'tenants' ? 'bg-blue-500 text-white' : 'bg-white'}`}
          onClick={() => setActiveTab('tenants')}
        >
          <PeopleIcon />
          <span className="hidden lg:block ml-2">Tenants</span>
        </button>
        <button
          className={`p-2 mb-2 flex items-center ${activeTab === 'properties' ? 'bg-blue-500 text-white' : 'bg-white'}`}
          onClick={() => setActiveTab('properties')}
        >
          <HomeIcon />
          <span className="hidden lg:block ml-2">Properties</span>
        </button>
        <button
          className={`p-2 flex items-center ${activeTab === 'landlords' ? 'bg-blue-500 text-white' : 'bg-white'}`}
          onClick={() => setActiveTab('landlords')}
        >
          <PersonIcon />
          <span className="hidden lg:block ml-2">Landlord</span>
        </button>

        <div className="py-2">
          <Logout />
        </div>

        <div className="py-2">
          <p className="text-sm text-gray-600">
            New to Coflow?{' '}
            <a href="https://joincoflow.notion.site" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
              Check our Quick-Start Guide
            </a>
          </p>
        </div>

      </div>

      <div className="flex-1 p-4">
        {activeTab === 'maintenance' && (
          <PMWorkItemsView
            workItems={workItems}
            properties={properties}
            onWorkItemClick={handleWorkItemClick}
            onAddWorkItem={handleAddWorkItem}
            onUpdateWorkItem={handleWorkItemUpdate}
          />
        )}
        {activeTab === 'tenants' && (
          <PMTenantsView
            tenants={tenants}
            properties={propertiesWithoutTenant}
            onAddTenant={handleAddTenant}
            onUpdateTenant={handleUpdateTenant}
          />
        )}
        {activeTab === 'properties' && (
          <PMPropertiesView
            properties={properties}
            landlords={landlords}
            propertyManagerId={user.id}
            onAddProperty={handleAddProperty}
            onUpdateProperty={handleUpdateProperty}
          />
        )}
        {activeTab === 'landlords' && (
          <PMLandlordsView
            landlords={landlords}
            onAddLandlord={handleAddLandlord}
            onUpdateLandlord={handleUpdateLandlord}
            propertyManagerId={user.id}
          />
        )}
      </div>

      {selectedWorkItem && (
        <WorkItemDetail workItem={selectedWorkItem} onClose={handleModalClose} onUpdate={handleWorkItemUpdate} />
      )}
    </div>
  );
};

export default PropertyManagement;

import React from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

interface PaymentFormProps {
  amount: number;
  clientSecret: string;
  onPaymentSuccess: () => void;
  contractorName: string;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ amount, clientSecret, onPaymentSuccess, contractorName }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      console.error(error.message);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      onPaymentSuccess();
    }
  };

  return (
    <div className="bg-gray-100 p-4 rounded-lg">
      <p className="mb-4">Enter your credit card details for the pre-payment of GBP {amount / 100} to {contractorName}</p>
      <small>Please note that the payment amount provided is an estimate and may vary. Any differences will be settled after the work has been done.</small>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <CardElement className="p-2 mb-4 border border-gray-300 rounded" />
        <button
          type="submit"
          className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200 ease-in-out"
          disabled={!stripe}
        >
          Prepayment
        </button>
      </form>
    </div>
  );
};

export default PaymentForm;

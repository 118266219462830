import React from 'react';

const PMLandlordForm = ({ newLandlord, handleInputChange, handleSubmit, handleClose, errorMessage, isEditMode }) => {
  return (
    <div className="bg-white w-96 p-4 rounded">
      <h2 className="text-2xl mb-4">{isEditMode ? 'Edit Landlord' : 'Add Landlord'}</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          name="name"
          value={newLandlord.name}
          onChange={handleInputChange}
          required={true}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          name="email"
          value={newLandlord.email}
          onChange={handleInputChange}
          required={true}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Phone</label>
        <input
          type="text"
          name="phone"
          value={newLandlord.phone}
          onChange={handleInputChange}
          required={true}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          {isEditMode ? 'New Password' : 'Password'}
        </label>
        <input
          type="password"
          name="password"
          value={newLandlord.password}
          onChange={handleInputChange}
          required={!isEditMode}
          autoComplete="new-password"
          placeholder={isEditMode ? 'Enter to change landlord\'s password' : 'Password'}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      {errorMessage && (
        <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
          {errorMessage}
        </div>
      )}
      <div className="flex justify-end">
        <button
          className="bg-gray-500 text-white px-4 py-2 mr-2 rounded"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleSubmit}
        >
          {isEditMode ? 'Save Changes' : 'Add Landlord'}
        </button>
      </div>
    </div>
  );
};

export default PMLandlordForm;

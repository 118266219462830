import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getWorkItems, createWorkItem, getTenantByEmail, updateTenant } from "../backend"; // Import updateTenant
import { addWorkItem } from "../actions/workItems";
import { RootState } from "../store";
import Modal from "../components/Modal";
import TenantWorkItemForm from "../components/TenantWorkItemForm";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Profile, Tenant, WorkItem } from "../types/types";
import { statusMappingTenant, UserRole, WorkItemStatus, WorkTypes } from "../types/globals";
import Logout from "../components/Logout";
import { MoonLoader } from "react-spinners";
import { loadProfile } from "../actions/profile";

const TenantPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const workItems = useAppSelector((state: RootState) => state.workItems.workItems);
  const user: Profile = useAppSelector((state: RootState) => state.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false); // State for Edit Profile modal
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for Dropdown menu
  const [isLoading, setIsLoading] = useState(false);
  const [property, setProperty] = useState<Tenant["property"] | null>(null);

  const [tenantDetails, setTenantDetails] = useState<Tenant | null>(null); // State to hold tenant details

  // State to hold new work item details
  const [newWorkItem, setNewWorkItem] = useState<Partial<WorkItem>>({
    description: "",
    photos: [],
    propertyId: null,
    workItemType: null,
  });

  // Reference for storing callbacks to be executed after state updates
  const callbackRef = useRef<((updatedWorkItem: Partial<WorkItem>) => void) | null>(null);

  useEffect(() => {
    // Fetch tenant information and work items when the component mounts
    const fetchTenantInfo = async () => {
      setIsLoading(true);
      try {
        if (user && user.email) {
          const tenantInfo: Tenant = await getTenantByEmail(user.email);
          if (tenantInfo && tenantInfo.property) {
            setProperty(tenantInfo.property);
            setTenantDetails(tenantInfo);
            setNewWorkItem((prevState) => ({
              ...prevState,
              propertyId: tenantInfo.property.id,
              threshold: tenantInfo.property.defaultThreshold,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching tenant info:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchWorkItems = async () => {
      setIsLoading(true); // Start loading
      try {
        const items = await getWorkItems("tenant", user.email);
        items.forEach((item) => {
          dispatch(addWorkItem(item));
        });
      } catch (error) {
        console.error("Error fetching work items:", error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchTenantInfo(), fetchWorkItems()]);
    };

    fetchData();
  }, [user, dispatch]);

  // Run the callback whenever newWorkItem changes and a callback is set
  useEffect(() => {
    console.log("newWorkItem @ useEffect: ", newWorkItem);
    if (callbackRef.current) {
      callbackRef.current(newWorkItem); // Pass newWorkItem to the callback
      callbackRef.current = null; // Clear the callback after execution
    }
  }, [newWorkItem]);

  // Handles modal state for adding a new work request
  const handleAddRequest = () => {
    setIsModalOpen(true);
  };

  // Handles form changes and updates the newWorkItem state
  const handleFormChange = (
    updatedItem: Partial<WorkItem>,
    callback?: (updatedWorkItem: Partial<WorkItem>) => void
  ) => {
    setNewWorkItem((prev) => {
      console.log("prev @ handleChange: ", prev);
      const newState = { ...prev, ...updatedItem };
      console.log("newState @ handleChange: ", newState);
      if (callback) {
        callbackRef.current = callback; // Store the callback in the ref
      }
      return newState;
    });
  };

  // Submits the form using the updated newWorkItem
  const handleFormSubmit = async (updatedWorkItem: Partial<WorkItem>) => {
    setIsLoading(true); // Start loading
    try {
      console.log("Before submit, newWorkItem: ", updatedWorkItem);

      const item: WorkItem = {
        ...updatedWorkItem,
        description: updatedWorkItem.description || "",
        photos: updatedWorkItem.photos || [],
        workItemType: updatedWorkItem.workItemType || "",
        status: WorkItemStatus.INITIAL_REPORT,
        quotes: [],
        createdAt: new Date(),
      } as WorkItem;

      console.log("New WorkItem: ", item);

      // Create the work item and update the store
      const createdItem = await createWorkItem(item);
      dispatch(addWorkItem(createdItem));
      setIsModalOpen(false); // Close the modal after submission
    } catch (error) {
      console.error("Error creating work item:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleProfileUpdate = async () => {
    console.log("tenantDetails: ", tenantDetails);
    if (!tenantDetails) return;
    setIsLoading(true);
    try {
      const updatedTenant = await updateTenant(tenantDetails.id, {
        name: tenantDetails.name,
        email: tenantDetails.email,
        phone: tenantDetails.phone,
        secondaryName: tenantDetails.secondaryName,
        secondaryEmail: tenantDetails.secondaryEmail,
        secondaryPhone: tenantDetails.secondaryPhone,
      });

      // Dispatch the loadProfile action to update user profile in Redux store
      dispatch(loadProfile({
        user: updatedTenant,
        role: UserRole.Tenant,
        type: 'UPDATE_USER_PROFILE_SUCCESS'
      }));

      // Close the modal after saving changes
      setIsEditProfileOpen(false);
    } catch (error) {
      console.error("Error updating tenant profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading && !isModalOpen && !isEditProfileOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <MoonLoader color="#7E22CE" size={50} />
        </div>
      )}
      <header className="flex justify-between items-center p-4 bg-gray-50 border-b border-gray-200">
        <Link to="/report">
          <div className="flex items-center">
            <img src="/favicon.png" className="h-6 mr-3 sm:h-9" alt="Coflow Logo" />
            <span className="self-center text-xl font-semibold whitespace-nowrap">
              Coflow Tenant Dashboard
            </span>
          </div>
        </Link>
        <div className="relative text-gray-700">
          {/* Dropdown for Edit Profile and Logout */}
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center space-x-2"
          >
            <span>Hello, {user?.name || "Tenant"}</span>
            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414L10 13.414l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg">
              <button
                onClick={() => {
                  setIsEditProfileOpen(true);
                  setIsDropdownOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Edit Profile
              </button>
              <Logout />
            </div>
          )}
        </div>
      </header>

      <div className="p-4">
        {/* Flex container to align heading and button */}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl">Ongoing Works at Your Property</h1>
          <button
            onClick={handleAddRequest}
            className="px-4 py-2 bg-blue-500 text-white rounded"
            disabled={isLoading} // Disable button when loading
          >
            Add a Repair Request
          </button>
        </div>
        {workItems.map((item) => (
          <div key={item.id} className="mb-4 p-4 border border-gray-200 rounded shadow">
            <div>{WorkTypes[item.workItemType] || item.workItemType}</div>
            <div>{statusMappingTenant[item.status]}</div>
          </div>
        ))}
      </div>

      {/* Modals */}
      {isModalOpen && property && (
        <Modal>
          <TenantWorkItemForm
            workItem={newWorkItem}
            onFormChange={handleFormChange}
            onFormSubmit={handleFormSubmit}
            onFormClose={() => setIsModalOpen(false)}
            propertyAddress={property.address}
            isLoading={isLoading} // Pass the loading state if needed
          />
        </Modal>
      )}

      {isEditProfileOpen && tenantDetails && (
        <Modal>
          <div className="bg-white w-96 p-6 rounded">
            <h2 className="text-2xl mb-4">Edit Profile</h2>
            <form className="space-y-4">
              {/* Name */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  value={tenantDetails.name}
                  onChange={(e) => setTenantDetails({ ...tenantDetails, name: e.target.value })}
                  placeholder="Name"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              {/* Email */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  value={tenantDetails.email}
                  onChange={(e) => setTenantDetails({ ...tenantDetails, email: e.target.value })}
                  placeholder="Email"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              {/* Phone */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="text"
                  value={tenantDetails.phone}
                  onChange={(e) => setTenantDetails({ ...tenantDetails, phone: e.target.value })}
                  placeholder="Phone"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              {/* Secondary Name */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Alternative Contact Person</label>
                <input
                  type="text"
                  value={tenantDetails.secondaryName || ""}
                  onChange={(e) => setTenantDetails({ ...tenantDetails, secondaryName: e.target.value })}
                  placeholder="Alternative Contact Person"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              {/* Secondary Email */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Alternative Email</label>
                <input
                  type="email"
                  value={tenantDetails.secondaryEmail || ""}
                  onChange={(e) => setTenantDetails({ ...tenantDetails, secondaryEmail: e.target.value })}
                  placeholder="Alternative Email"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              {/* Secondary Phone */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Alternative Phone</label>
                <input
                  type="text"
                  value={tenantDetails.secondaryPhone || ""}
                  onChange={(e) => setTenantDetails({ ...tenantDetails, secondaryPhone: e.target.value })}
                  placeholder="Alternative Phone"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              {/* Buttons */}
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsEditProfileOpen(false)} // Close the modal without saving
                  className="bg-gray-500 text-white px-4 py-2 mr-2 rounded hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleProfileUpdate}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  disabled={isLoading} // Disable button when loading
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}

    </div>
  );
};

export default TenantPage;
